import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setLoading, clearLoading } from "./loadingSlice";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    saveOrder: builder.mutation({
      query: ({ accessToken, data }) => {
        return {
          url: "order/save",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrder: builder.mutation({
      query: ({ accessToken, data }) => {
        return {
          url: "order/update",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrder: builder.mutation({
      query: ({ accessToken, _id }) => {
        var formData = {
          _id: _id,
        };

        return {
          url: "order/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderPriority: builder.mutation({
      query: ({ accessToken, serviceObjId, isPriority, expectedDelivery }) => {
        var formData = {
          serviceObjId: serviceObjId,
          isPriority: isPriority,
          expectedDelivery: expectedDelivery,
        };

        return {
          url: "order/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderPriority: builder.mutation({
      query: ({ accessToken, _id, isPriority }) => {
        var formData = {
          _id: _id,
          isPriority: isPriority,
        };

        return {
          url: "order/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderPatient: builder.mutation({
      query: ({
        accessToken,
        _id,
        patientInitials,
        detailsAnonymized,
        dob,
        gender,
        address,
        country,
        pinCode,
        latitude,
        longitude,
      }) => {
        var formData = {
          _id: _id,
          patientInitials: patientInitials,
          detailsAnonymized: detailsAnonymized,
          dob: dob,
          gender: gender,
          address: address,
          country: country,
          pinCode: pinCode,
          latitude: latitude,
          longitude: longitude,
        };

        return {
          url: "orderpatient/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderPatient: builder.mutation({
      query: ({
        accessToken,
        _id,
        patientInitials,
        detailsAnonymized,
        dob,
        gender,
        address,
        country,
        pinCode,
        latitude,
        longitude,
      }) => {
        var formData = {
          _id: _id,
          patientInitials: patientInitials,
          detailsAnonymized: detailsAnonymized,
          dob: dob,
          gender: gender,
          address: address,
          country: country,
          pinCode: pinCode,
          latitude: latitude,
          longitude: longitude,
        };

        return {
          url: "orderpatient/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderMissingTeeth: builder.mutation({
      query: ({ accessToken, _id, teethno }) => {
        var formData = {
          _id: _id,
          teethno: teethno,
        };

        return {
          url: "orderCase/missingtooth/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderMissingTeeth: builder.mutation({
      query: ({ accessToken, _id, teethno }) => {
        var formData = {
          _id: _id,
          teethno: teethno,
        };

        return {
          url: "orderCase/missingtooth/deleteData",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderNotToMoveTeeth: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, teethno }) => {
        var formData = {
          _id: _id,
          toothNumber: teethno,
          serviceObjId: serviceObjId,
        };

        return {
          url: "orders/v1/services/subTeethNotToMove/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderNotToMoveTeeth: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, teethno }) => {
        var formData = {
          _id: _id,
          toothNumber: teethno,
          serviceObjId: serviceObjId,
        };

        return {
          url: "orders/v1/services/subTeethNotToMove/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderNeedToExtractTeeth: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, teethno }) => {
        var formData = {
          _id: _id,
          toothNumber: teethno,
          serviceObjId: serviceObjId,
        };

        return {
          url: "orders/v1/services/teethNeedToExtract/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderNeedToExtractTeeth: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, teethno }) => {
        var formData = {
          _id: _id,
          toothNumber: teethno,
          serviceObjId: serviceObjId,
        };

        return {
          url: "orders/v1/services/teethNeedToExtract/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderTeethToExtract: builder.mutation({
      query: ({ accessToken, _id, teethno }) => {
        var formData = {
          _id: _id,
          teethno: teethno,
        };

        return {
          url: "orderCase/teethtoextract/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderTeethToExtract: builder.mutation({
      query: ({ accessToken, _id, teethno }) => {
        var formData = {
          _id: _id,
          teethno: teethno,
        };

        return {
          url: "orderCase/teethtoextract/deleteData",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderServicesRequired: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, service }) => {
        var formData = {
          _id: _id,
          service: service,
          serviceObjId: serviceObjId,
        };

        return {
          url: "orders/v1/services/servicesRequired/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderServicesRequired: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, service }) => {
        var formData = {
          _id: _id,
          service: service,
          serviceObjId: serviceObjId,
        };

        return {
          url: "orders/v1/services/servicesRequired/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderAnyTeethMobile: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, teethno }) => {
        var formData = {
          _id: _id,
          teethno: teethno,
        };

        return {
          url: "orderCase/anyteethmobile/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderAnyTeethMobile: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, teethno }) => {
        var formData = {
          _id: _id,
          teethno: teethno,
        };

        return {
          url: "orderCase/anyteethmobile/deleteData",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderCrownType: builder.mutation({
      query: ({ accessToken, _id, type, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          type: type,
        };

        return {
          url: "orders/v1/services/crownType/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderCrownType: builder.mutation({
      query: ({ accessToken, _id, type, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          type: type,
        };

        return {
          url: "orders/v1/services/crownType/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderClinicalConditions: builder.mutation({
      query: ({ accessToken, _id, condition_name, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          condition_name: condition_name,
        };

        return {
          url: "orders/v1/services/clinicalConditions/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderClinicalConditions: builder.mutation({
      query: ({ accessToken, _id, condition_name, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          condition_name: condition_name,
        };

        return {
          url: "orders/v1/services/clinicalConditions/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderMajorConnector: builder.mutation({
      query: ({ accessToken, _id, connectorName, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          connectorName: connectorName,
        };

        return {
          url: "orders/v1/services/majorConnector/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderMajorConnector: builder.mutation({
      query: ({ accessToken, _id, connectorName, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          connectorName: connectorName,
        };

        return {
          url: "orders/v1/services/majorConnector/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderVeneerType: builder.mutation({
      query: ({ accessToken, _id, type, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          type: type,
        };

        return {
          url: "orders/v1/services/veneerType/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderVeneerType: builder.mutation({
      query: ({ accessToken, _id, type, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          type: type,
        };

        return {
          url: "orders/v1/services/veneerType/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderMidline: builder.mutation({
      query: ({ accessToken, _id, midline, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          midline: midline,
        };

        return {
          url: "orders/v1/services/midline/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderMidline: builder.mutation({
      query: ({ accessToken, _id, midline, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          midline: midline,
        };

        return {
          url: "orders/v1/services/midline/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderProcessType: builder.mutation({
      query: ({ accessToken, _id, preferredRestorativeSpace, process, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          preferredRestorativeSpace: preferredRestorativeSpace,
          process: process,
        };

        return {
          url: "orders/v1/services/processType/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderProcessType: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, preferredRestorativeSpace, process }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          preferredRestorativeSpace: preferredRestorativeSpace,
          process: process,
        };

        return {
          url: "orders/v1/services/processType/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderAdditionalGuideOptions: builder.mutation({
      query: ({ accessToken, _id, option, position, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          option: option,
          position: position,
        };

        return {
          url: "orders/v1/services/additionalGuideOptions/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderAdditionalGuideOptions: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, option, position }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          option: option,
          position: position,
        };

        return {
          url: "orders/v1/services/additionalGuideOptions/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderAdditionalGuideOptions: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, option, position }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          option: option,
          position: position,
        };

        return {
          url: "orders/v1/services/additionalGuideOptions/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderPrintableModel: builder.mutation({
      query: ({ accessToken, _id, includeDies, modelRequired, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          includeDies: includeDies,
          modelRequired: modelRequired,
        };

        return {
          url: "orders/v1/services/printableModelRequired/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderPrintableModel: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, includeDies, modelRequired }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          includeDies: includeDies,
          modelRequired: modelRequired,
        };

        return {
          url: "orders/v1/services/printableModelRequired/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderOverjetCorrection: builder.mutation({
      query: ({ accessToken, _id, improveLength, option, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          improveLength: improveLength,
          option: option,
        };

        return {
          url: "orders/v1/services/overjetCorrection/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderOverjetCorrection: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, improveLength, option }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          improveLength: improveLength,
          option: option,
        };

        return {
          url: "orders/v1/services/overjetCorrection/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderOverbiteCorrection: builder.mutation({
      query: ({ accessToken, _id, improveLength, option, serviceObjId }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          improveLength: improveLength,
          option: option,
        };

        return {
          url: "orders/v1/services/overbiteCorrection/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderOverbiteCorrection: builder.mutation({
      query: ({ accessToken, _id, serviceObjId, improveLength, option }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          improveLength: improveLength,
          option: option,
        };

        return {
          url: "orders/v1/services/overbiteCorrection/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderServiceDetails: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/save",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderServiceDetails: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/update",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderServiceDetails: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/delete",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderServiceTeeth: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/tooth/save",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderServiceTeeth: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/tooth/update",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderServiceTeeth: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/tooth/delete",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderServiceMultiField: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/option/save",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderServiceMultiField: builder.mutation({
      query: ({ accessToken, ...data }) => {
        return {
          url: "orderservice/option/delete",
          method: "POST",
          body: data,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderDisclaimer: builder.mutation({
      query: ({ accessToken, _id, isTncChecked, tncDocument }) => {
        var formData = {
          _id: _id,
          isTncChecked: isTncChecked,
          tncDocument: tncDocument,
        };

        return {
          url: "order/disclaimer/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderDisclaimer: builder.mutation({
      query: ({ accessToken, _id, isTncChecked, tncDocument }) => {
        var formData = {
          _id: _id,
          isTncChecked: isTncChecked,
          tncDocument: tncDocument,
        };

        return {
          url: "order/disclaimer/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderClinical: builder.mutation({
      query: ({ accessToken, orderObjId, clinicalConditions }) => {
        var formData = {
          orderObjId: orderObjId,
          clinicalConditions: clinicalConditions,
        };

        return {
          url: "orderclinical/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderClinical: builder.mutation({
      query: ({ accessToken, _id, clinicalConditions }) => {
        var formData = {
          _id: _id,
          clinicalConditions: clinicalConditions,
        };

        return {
          url: "orderclinical/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderScans: builder.mutation({
      query: ({ accessToken, orderObjId, fileType, fileFor, s3Url }) => {
        var formData = {
          orderObjId: orderObjId,
          fileType: fileType,
          s3Url: s3Url,
          fileFor: fileFor,
        };
        return {
          url: "orderScans/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderScans: builder.mutation({
      query: ({ accessToken, _id, s3Url, fileFor }) => {
        var formData = {
          _id: _id,
          s3Url: s3Url,
          fileFor: fileFor,
        };

        return {
          url: "orderScans/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderScans: builder.mutation({
      query: ({ accessToken, _id }) => {
        var formData = {
          _id: _id,
        };

        return {
          url: "orderScans/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderTreatmentGlsData: builder.mutation({
      query: ({ accessToken, orderObjId, treatmentGoals }) => {
        var formData = {
          orderObjId: orderObjId,
          treatmentGoals: treatmentGoals,
        };

        return {
          url: "orderTreatmentGls/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderTreatmentGlsData: builder.mutation({
      query: ({ accessToken, _id, treatmentGoals }) => {
        var formData = {
          _id: _id,
          treatmentGoals: treatmentGoals,
        };

        return {
          url: "orderTreatmentGls/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderTreatmentGlsData: builder.mutation({
      query: ({ accessToken, _id, treatmentGoals }) => {
        var formData = {
          _id: _id,
          treatmentGoals: treatmentGoals,
        };

        return {
          url: "orderTreatmentGls/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderToothMovData: builder.mutation({
      query: ({ accessToken, orderObjId, toothMovementData }) => {
        var formData = {
          orderObjId: orderObjId,
          toothMovementData: toothMovementData,
        };

        return {
          url: "orderToothMov/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderToothMovData: builder.mutation({
      query: ({ accessToken, _id, toothMovementData }) => {
        var formData = {
          _id: _id,
          toothMovementData: toothMovementData,
        };

        return {
          url: "orderToothMov/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    deleteOrderToothMovData: builder.mutation({
      query: ({ accessToken, _id, toothMovementData }) => {
        var formData = {
          _id: _id,
          toothMovementData: toothMovementData,
        };

        return {
          url: "orderToothMov/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveOrderTeethData: builder.mutation({
      query: ({ accessToken, orderObjId, teeth }) => {
        var formData = {
          orderObjId: orderObjId,
          toothData: teeth,
        };

        return {
          url: "orderTooth/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderTeethData: builder.mutation({
      query: ({ accessToken, _id, teeth }) => {
        var formData = {
          _id: _id,
          toothData: teeth,
        };

        return {
          url: "orderTooth/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getOrders: builder.mutation({
      query: ({ accessToken, data }) => ({
        url: "order/read",
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getOrdersForAdmin: builder.mutation({
      query: (accessToken) => ({
        url: "order/readad",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getOrdersForDentist: builder.mutation({
      query: ({ accessToken, data }) => ({
        url: "order/readdd",
        method: "POST",
        body: data,
        formData: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getOrdersForServiceProvider: builder.mutation({
      query: (accessToken) => ({
        url: "order/readsd",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getOrderById: builder.mutation({
      query: ({ accessToken, orderId }) => {
        var formData = {
          orderObjId: orderId,
        };

        return {
          url: "order/readbyid",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateOrderById: builder.mutation({
      query: ({ accessToken, orderId, currentStatus }) => {
        var formData = {
          _id: orderId,
          currentStatus: currentStatus,
        };

        return {
          url: "order/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getLatestOrderPatientDetails: builder.mutation({
      query: ({ accessToken }) => {
        return {
          url: "order/patientdetails/latest",
          method: "POST",
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getServiceProviderByService: builder.mutation({
      query: ({ accessToken, serviceObjId }) => {
        var formData = {
          serviceObjId: serviceObjId,
        };

        return {
          url: "/spsbyservice",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    getServiceProviderOrders: builder.mutation({
      query: ({ accessToken, orderObjId }) => {
        var formData = {
          orderObjId: orderObjId,
        };

        return {
          url: "/sporders/read",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveServiceProviderOrders: builder.mutation({
      query: ({
        accessToken,
        orderObjId,
        spuserAuthId,
        orderValue,
        portalValue,
        orderCurrency,
        userServiceObjId,
      }) => {
        var formData = {
          orderObjId: orderObjId,
          spuserAuthId: spuserAuthId,
          orderValue: orderValue,
          orderCurrency: orderCurrency,
          userServiceObjId: userServiceObjId,
          portalValue: portalValue,
        };

        return {
          url: "/sporders/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateServiceProviderOrders: builder.mutation({
      query: ({ accessToken, orderObjId, orderValue, portalValue, orderCurrency }) => {
        var formData = {
          orderObjId: orderObjId,
          orderValue: orderValue,
          orderCurrency: orderCurrency,
          portalValue: portalValue,
        };

        return {
          url: "/sporders/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    saveCommOrders: builder.mutation({
      query: ({
        accessToken,
        orderObjId,
        typofCommunication,
        communicationTile,
        imageurl,
        communicationText,
        commStatus,
        readStatus,
        parentObjId,
      }) => {
        var formData = {
          orderObjId: orderObjId,
          imageurl: imageurl,
          typofCommunication: typofCommunication,
          communicationTile: communicationTile,
          communicationText: communicationText,
          commStatus: commStatus,
          readStatus: readStatus,
          parentObjId: parentObjId,
        };

        return {
          url: "/ordercomm/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    updateCommOrders: builder.mutation({
      query: ({ accessToken, _id, communicationTile, communicationText }) => {
        var formData = {
          _id: _id,
          communicationTile: communicationTile,
          communicationText: communicationText,
        };

        return {
          url: "/ordercomm/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
    readCommOrders: builder.mutation({
      query: ({ accessToken, orderObjId, typofCommunication }) => {
        var formData = {
          orderObjId: orderObjId,
          typofCommunication: typofCommunication,
        };

        return {
          url: "/ordercomm/read",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      onStart: (args, { dispatch }) => {
        dispatch(setLoading("saveCategory"));
      },
      onFinish: (args, { dispatch }) => {
        dispatch(clearLoading("saveCategory"));
      },
    }),
  }),
});

export const {
  useGetOrdersMutation,
  useSaveOrderMutation,
  useUpdateOrderMutation,
  useSaveOrderPriorityMutation,
  useGetOrdersForAdminMutation,
  useUpdateOrderPriorityMutation,
  useGetOrdersForDentistMutation,
  useGetLatestOrderPatientDetailsMutation,
  useGetOrdersForServiceProviderMutation,
  useGetOrderByIdMutation,
  useGetServiceProviderByServiceMutation,
  useGetServiceProviderOrdersMutation,
  useSaveServiceProviderOrdersMutation,
  useUpdateServiceProviderOrdersMutation,
  useUpdateOrderByIdMutation,
  useSaveOrderPatientMutation,
  useSaveOrderClinicalMutation,
  useUpdateOrderClinicalMutation,
  useUpdateOrderPatientMutation,
  useSaveOrderDisclaimerMutation,
  useUpdateOrderDisclaimerMutation,
  useSaveOrderScansMutation,
  useUpdateOrderScansMutation,
  useDeleteOrderScansMutation,
  useSaveOrderTreatmentGlsDataMutation,
  useSaveOrderToothMovDataMutation,
  useUpdateOrderTreatmentGlsDataMutation,
  useUpdateOrderToothMovDataMutation,
  useDeleteOrderTreatmentGlsDataMutation,
  useDeleteOrderToothMovDataMutation,
  useSaveOrderTeethDataMutation,
  useUpdateOrderTeethDataMutation,
  useDeleteOrderMutation,
  useSaveCommOrdersMutation,
  useUpdateCommOrdersMutation,
  useReadCommOrdersMutation,
  useSaveOrderMissingTeethMutation,
  useDeleteOrderMissingTeethMutation,
  useSaveOrderNotToMoveTeethMutation,
  useDeleteOrderNotToMoveTeethMutation,
  useSaveOrderServiceTeethMutation,
  useUpdateOrderServiceTeethMutation,
  useDeleteOrderServiceTeethMutation,
  useSaveOrderClinicalConditionsMutation,
  useDeleteOrderClinicalConditionsMutation,
  useSaveOrderCrownTypeMutation,
  useDeleteOrderCrownTypeMutation,
  useSaveOrderVeneerTypeMutation,
  useDeleteOrderVeneerTypeMutation,
  useSaveOrderProcessTypeMutation,
  useUpdateOrderProcessTypeMutation,
  useSaveOrderAdditionalGuideOptionsMutation,
  useUpdateOrderAdditionalGuideOptionsMutation,
  useDeleteOrderAdditionalGuideOptionsMutation,
  useSaveOrderPrintableModelMutation,
  useUpdateOrderPrintableModelMutation,
  useSaveOrderServiceDetailsMutation,
  useUpdateOrderServiceDetailsMutation,
  useDeleteOrderServiceDetailsMutation,
  useSaveOrderMidlineMutation,
  useDeleteOrderMidlineMutation,
  useSaveOrderOverjetCorrectionMutation,
  useUpdateOrderOverjetCorrectionMutation,
  useSaveOrderOverbiteCorrectionMutation,
  useUpdateOrderOverbiteCorrectionMutation,
  useSaveOrderNeedToExtractTeethMutation,
  useDeleteOrderNeedToExtractTeethMutation,
  useSaveOrderMajorConnectorMutation,
  useDeleteOrderMajorConnectorMutation,
  useSaveOrderAnyTeethMobileMutation,
  useDeleteOrderAnyTeethMobileMutation,
  useSaveOrderTeethToExtractMutation,
  useDeleteOrderTeethToExtractMutation,
  useSaveOrderServicesRequiredMutation,
  useDeleteOrderServicesRequiredMutation,
  useSaveOrderServiceMultiFieldMutation,
  useDeleteOrderServiceMultiFieldMutation,
} = ordersApi;
