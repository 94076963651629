import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import html2canvas from "html2canvas";
import Modal from "react-modal";
import jsPDF from "jspdf";

import Notification from "../../Components/Notification/Notification";
import { LoginButton } from "../Login/Login";

import { OPTIONS_VALUE, ORDER_STAGE } from "../../constants/orderForms";

import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import imagePlaceholderIcon from "../../Assets/images/imagePlaceholderIcon.png";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";
import { isImageFormatDisplayable } from "../../Utils/utility";

function CrownOrderConfirmation({
  orderFor,
  orderObjId,
  userInfo,
  userRole,
  setActiveTab,
  selectedServiceCategory,
  setIsDisclaimerAccepted,
  categoryObjId,
  isOrderPlaced = false,
  placeOrderHandler,
}) {
  return (
    <div className="flex w-full">
      <OrderConfirmationTab
        orderObjId={orderObjId}
        userRole={userRole}
        userInfo={userInfo}
        setActiveTab={setActiveTab}
        selectedServiceCategory={selectedServiceCategory}
        setIsDisclaimerAccepted={setIsDisclaimerAccepted}
        isOrderPlaced={isOrderPlaced}
        placeOrderHandler={placeOrderHandler}
      />
    </div>
  );
}

export default CrownOrderConfirmation;

export const OrderConfirmationTab = ({
  userInfo,
  userRole,
  orderObjId,
  setActiveTab,
  selectedServiceCategory,
  setIsDisclaimerAccepted,
  isOrderPlaced,
  placeOrderHandler,
}) => {
  const pdfRef = useRef();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();

  const { crownAndBridgeOrderDetails } = useSelector((state) => state.orderFormDetails);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  console.log("selectedServiceCategory", selectedServiceCategory);
  console.log("crownAndBridgeOrderDetails", crownAndBridgeOrderDetails);

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  console.log("crownAndBridgeOrderDetails", crownAndBridgeOrderDetails);

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width * 0.264583; // Convert pixels to mm (1 px = 0.264583 mm)
      const pdfHeight = canvas.height * 0.264583; // Convert pixels to mm

      const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full bg-secondary p-4 rounded-t-xl">
        <p className="font-medium text-[34px] text-white">Crown and Bridge</p>
        {!isOrderPlaced && (
          <>
            <div onClick={downloadPDF} className="flex gap-4 items-center">
              <img src={printerIcon} alt="" className="cursor-pointer" />
              <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
                <img src={exitIcon} alt="" />
                Export
              </button>
            </div>
            <div className="flex items-center md:justify-end gap-4 flex-wrap">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setActiveTab(ORDER_STAGE.DISCLAIMER);
                  setIsDisclaimerAccepted(false);
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Back
              </button>
              <button
                onClick={() => {
                  placeOrderHandler();
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
              >
                Get Quote
              </button>
            </div>
          </>
        )}
      </div>

      <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full bg-secondary rounded-b-xl px-4 w-full">
        <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md">
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className=" text-white md:block hidden ">Service Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className="text-white md:block hidden ">Order Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            {isOrderPlaced ? (
              <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
            ) : (
              <div className="bg-orange-500 h-8 w-8 rounded-full border text-[#fff] font-medium flex items-center justify-center">
                3
              </div>
            )}

            <p className="text-white font-semibold md:block hidden ">Confirmation</p>
          </div>
        </div>
        {isOrderPlaced ? (
          <div className="flex-1 flex flex-col justify-between h-full">
            <div className="h-full w-full flex-1 flex flex-col justify-center items-center gap-2">
              <img src={orderPlacedIcon} alt="" />
              <p className="text-2xl text-white font-semibold">Order Placed Successfully</p>
              <p className="text-[17px] text-white">
                Order has been sent for review will get back to you with the best possible quote in
                48 hours
              </p>
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full bg-secondary p-3" ref={pdfRef}>
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient Initials</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.patientInitials || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient details have been anonymized</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.detailsAnonymized ? "Yes" : "No"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Date of Birth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.dob || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Gender</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.gender || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Shipping Address</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.address || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Country</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.country || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Postal code (Zip code/Pin code)</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.patientDetails?.pinCode || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">The patient has any missing teeth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.caseDetails?.hasMissingTeeth || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Missing Teeths</p>
                  <p className="text-[#555] font-medium mt-2">
                    {crownAndBridgeOrderDetails?.caseDetails?.missingTeethSelectedTeeth?.join(
                      ", "
                    ) || "NA"}
                  </p>
                </div>

                {crownAndBridgeOrderDetails?.caseDetails?.services?.length > 0 && (
                  <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-xl text-white text-center uppercase">Services Selected</p>
                  </div>
                )}
              </div>

              {crownAndBridgeOrderDetails?.caseDetails?.services?.map((service) => {
                const serviceDetails = selectedServiceCategory?.catservices?.find(
                  (d) => d?.serviceObjId === service?.serviceObjId
                );
                if (serviceDetails?.serviceObjId) {
                  return (
                    <div
                      key={"serviceItem-" + serviceDetails?.serviceObjId}
                      className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
                    >
                      <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                        <p className="text-[15px] text-white font-semibold">Service Name</p>
                        <p className="text-primary font-medium mt-2 uppercase">
                          {serviceDetails?.serviceName}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Restoration and/or pontic sites</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.restorationSites?.length > 0
                            ? service?.restorationSites?.join(", ")
                            : "NA"}
                        </p>
                      </div>
                      {[
                        "Single or Multiple single crowns",
                        "Bridge(s)",
                        "Bridge(s) with gingiva",
                        "Maryland Bridge",
                        "Post and core crown",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            {["Bridge(s)", "Bridge(s) with gingiva", "Maryland Bridge"].includes(
                              serviceDetails?.serviceName
                            )
                              ? "Type of Bridge Retainer and Pontic"
                              : "Type of Crown"}
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.typeOfCrown?.length > 0
                              ? service?.typeOfCrown.join(", ")
                              : "NA"}
                          </p>
                        </div>
                      )}
                      {serviceDetails?.serviceName === "Veneers" && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Type of Veneer</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.typeOfVeneer?.length > 0
                              ? service?.typeOfVeneer.join(", ")
                              : "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Cement Gap (µ microns)</p>
                        <p className="text-[#555] font-medium mt-2">{service?.cementGap || "NA"}</p>
                      </div>
                      {[
                        "Single or Multiple single crowns",
                        "Bridge(s)",
                        "Bridge(s) with gingiva",
                        "Maryland Bridge",
                        "Veneers",
                        "Onlays",
                        "Post and core crown",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Interproximal Contacts</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.interproximalContacts || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Occlusal Contacts</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.occlusalContacts || "NA"}
                        </p>
                      </div>
                      {["Bridge(s)", "Bridge(s) with gingiva", "Maryland Bridge"].includes(
                        serviceDetails?.serviceName
                      ) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Pontic Design</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.ponticDesign || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          Do you need to see a design preview?
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.needDesignPreview || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Will you be Milling or Printing?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.processType || "NA"}
                        </p>
                      </div>
                      {service?.processType === OPTIONS_VALUE.PRINTING && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            If you are printing what restorative space do you prefer? (µ microns)
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.processTypePreferredRestorativeSpace || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Design file to be provided?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.designFileNeeded || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Preferred design software?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.preferredSoftware || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          Restorations are normally provided as .STLS, Please advise if a
                          construction.info file is desired.
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.constructionFileNeeded || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          Is a printable restorative model desired?
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.printableModelRequired || "NA"}
                        </p>
                      </div>
                      {service?.printableModelRequired === "Yes" && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            Should the crown and bridge model include dies?
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.printableModelincludeDies || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          If a certain restoration lacks occlusal clearance? Please advise your
                          preferred course of action
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.restorationLacksOC || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Additional Instructions</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.additionalInstructions || "NA"}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE md:col-span-3 pb-4 border-b uppercase border-1 border-dotted border-white border-opacity-20">
                  <p className="text-xl text-white text-center">Uploads</p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Photographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.photographs)?.map(
                      function (key, index) {
                        if (crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]?.s3Url) {
                          return (
                            <div
                              key={"photographItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                                  )
                                    ? crownAndBridgeOrderDetails?.uploadFiles?.photographs[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.photographs || {})?.length ===  0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Radiographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.radiographs)?.map(
                      function (key, index) {
                        if (crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]?.s3Url) {
                          return (
                            <div
                              key={"radiographItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                                  )
                                    ? crownAndBridgeOrderDetails?.uploadFiles?.radiographs[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.radiographs || {})?.length ===  0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Intra Oral Scans</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans)?.map(
                      function (key, index) {
                        if (crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url) {
                          return (
                            <div
                              key={"intraOralScansItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]
                                    ?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]
                                      ?.s3Url
                                  )
                                    ? crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.intraOralScans || {})?.length ===  0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Raw DICOM Data</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData)?.map(
                      (key, index) => {
                        if (crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]
                                      ?.s3Url
                                  )
                                    ? crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.rawDicomData || {})?.length ===  0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Additional Files</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.additionalFiles)?.map(
                      (key, index) => {
                        if (
                          crownAndBridgeOrderDetails?.uploadFiles?.additionalFiles?.[key]?.s3Url
                        ) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  crownAndBridgeOrderDetails?.uploadFiles?.additionalFiles?.[key]
                                    ?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    crownAndBridgeOrderDetails?.uploadFiles?.additionalFiles[key]
                                      ?.s3Url
                                  )
                                    ? crownAndBridgeOrderDetails?.uploadFiles?.additionalFiles[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(crownAndBridgeOrderDetails?.uploadFiles?.additionalFiles || {})?.length ===  0 && "NA"}
                  </div>
                </div>
                {isImageModalOpen && (
                  <div className="image-modal blur-md">
                    <Modal
                      isOpen={isImageModalOpen}
                      onRequestClose={() => setIsImageModalOpen(false)}
                      contentLabel="Custom Modal"
                      className="image-modal !bg-black/80"
                      // style={customStyles}
                      ariaHideApp={false}
                    >
                      <div
                        className="MODAL-BODY flex flex-col h-full"
                        onClick={() => closeImageModal()}
                      >
                        <button className="w-full text-right">
                          <AiOutlineClose className="ml-auto text-white" />
                        </button>
                        <img
                          className={`${
                            isImageFormatDisplayable(selectedImage) ? "h-[90%]" : "h-[85%]"
                          } w-full object-contain`}
                          src={
                            isImageFormatDisplayable(selectedImage)
                              ? selectedImage
                              : imagePlaceholderIcon
                          }
                          onClick={(e) => e.stopPropagation()}
                          alt=""
                        />
                        {!isImageFormatDisplayable(selectedImage) && (
                          <p className="mt-3 font-medium text-white text-lg text-center">
                            File Preview not available
                          </p>
                        )}
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
