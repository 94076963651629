import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
// eslint-disable-next-line no-unused-vars
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import TextInputField from "../../../Components/InputField-Comps/TextInputField";
import NumberInputField from "../../../Components/InputField-Comps/NumberInputField";
import DropdownField from "../../../Components/InputField-Comps/DropdownField";
import EmailInputField from "../../../Components/InputField-Comps/EmailInputField";
import MapInputField from "../../../Components/InputField-Comps/MapInputField";
import UseAccessToken from "../../../hooks/UseAccessToken";
import { useAuth0 } from "@auth0/auth0-react";
import ToothLogo from "../../../Assets/images/digitalLogo.png";
import { Accordion, AccordionItem } from "../../../Components/Accordian/Accordian";
import headerBG from "../../../Assets/images/headerBG.png";
import { useDispatch, useSelector } from "react-redux";
import ConnectingLine from "../../../Assets/images/connectingLine.png";
import ReactDOMServer from "react-dom/server";
import Modal from "react-modal";
import SignatureCanvas from "react-signature-canvas";

import {
  setDentistPersonalDetails,
  setDentistAddressDetails,
  setDentistClinicDetails,
} from "../../../reducers/registrationReducer";

import {
  useSavePersonalDetailsDtMutation,
  useSaveAddressDetailsMutation,
  useSaveClinicalDetailsMutation,
  useGetProfileMutation,
} from "../../../services/user";

import LocationInput from "../../../Components/InputField-Comps/LocationInput";
import { GetToast } from "../../../Components/Toast-Comps/GetToast";
import useWindowWidth from "../../../Components/Accordian/WindowsWidth";
import NumberInputWithCountryCodeField from "../../../Components/InputField-Comps/NumberInputWithCountryCodeField";
import ActiveToggleButton from "../../../Components/UserManagement-Comps/ActiveToggleButton";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import LocationInputV2 from "../../../Components/InputField-Comps/LocationInputV2";
import TextAreaInputField from "../../../Components/InputField-Comps/TextAreaInputField";
import { useRef } from "react";
import jsPDF from "jspdf";
import TimeZoneInputField from "../../../Components/InputField-Comps/TimeZoneInputField";
import { MAP_LIBRARIES } from "../../../Utils/utility";

function DentistForm({ emailFromUserProfile, setProfileId, userInfo }) {
  const { getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();
  const accessToken = UseAccessToken();
  const dispatch = useDispatch();
  const dentistDetails = useSelector((state) => state.registrationDetails.dentist);
  const [savePersonalDetailsDt] = useSavePersonalDetailsDtMutation();
  const [saveAddressDetails] = useSaveAddressDetailsMutation();
  const [saveClinicalDetails] = useSaveClinicalDetailsMutation();
  const [getProfile] = useGetProfileMutation();
  const tabNamesArray = [
    "Personal Details",
    "Clinical Information",
    "Address Details",
    "Terms & Conditions",
  ];

  //   use states
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  //profile details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [alertsForMobile, setAlertsForMobile] = useState(false);
  const [alertsForEmail, setAlertsForEmail] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  const [isProfileFormSubmitted, setIsProfileFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isProfileValid, setIsProfileValid] = useState(false);
  // ends here

  // clinic information states
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [regAuthority, setRegAuthority] = useState("");
  const [isClinicFormSubmitted, setIsClinicFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isClinicValid, setIsClinicValid] = useState(false);

  //   address details states
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [locationDentist, setLocationDentist] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const [isAddressFormSubmitted, setIsAddressFormSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isAddressValid, setIsAddressValid] = useState(false);
  //   ends here

  // Tnc Details here
  const [isTncChecked, setIsTncChecked] = useState(false);
  const [userSignature, setUserSignature] = useState(null);

  //Google Maps comps and state here
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  // Use Effects
  // useEffect(() => {
  //   // Simulating fetching options from an external source
  //   setTimeout(() => {
  //     const fetchedCountryOptions = [
  //       { value: "india", label: "india" },
  //       { value: "option2", label: "Option 2" },
  //       { value: "option3", label: "Option 3" },
  //     ];
  //     setCountryOptions(fetchedCountryOptions);
  //   }, 1000);
  // }, []);

  //   handlers

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };
  const handleBackTab = (tabName) => {
    const index = tabNamesArray.indexOf(tabName);
    const prevString = index > 0 ? tabNamesArray[index - 1] : null;
    if (index > 0) {
      switch (index) {
        case 1:
          setIsProfileFormSubmitted(false);
          break;
        case 2:
          setIsClinicFormSubmitted(false);
          break;
        case 3:
          setIsAddressFormSubmitted(false);
          break;
        default:
          break;
      }

      setActiveTab(prevString);
    }
  };
  const handleNextTab = (tabName) => {
    const index = tabNamesArray.indexOf(tabName);
    const nextString = tabNamesArray[index + 1];
    switch (tabName) {
      case "Personal Details":
        submitPersonDetails(nextString);
        break;
      case "Clinical Information":
        submitClinicInformation(nextString);
        break;
      case "Address Details":
        submitAddressDetails(nextString);
        break;
      case "Terms & Conditions":
        submitSignatureDetails(nextString);
        break;
      default:
        break;
    }
  };

  //   FUNCTIONS for form validation and submission
  //   FIRST FORM SUBMISSION FUNCTION
  const submitPersonDetails = async (nextString) => {
    // Perform validation for each input
    const isFirstNameValid = !isFieldRequired("firstName") || validateFirstName(firstName);
    const isLastNameValid = !isFieldRequired("lastName") || validateLastName(lastName);
    const isMobileNumberValid =
      !isFieldRequired("mobileNumber") || validateMobileNumberWithCountryCode(mobileNumber);
    const isTextMessageCheckedValid =
      !isFieldRequired("textMessages") || validateTextMessageChecked(alertsForMobile);
    const isEmailAlertsCheckedValid =
      !isFieldRequired("emailAlerts") || validateEmailAlertsChecked(alertsForEmail);

    // Set overall form validity
    setIsProfileValid(
      isFirstNameValid &&
        isLastNameValid &&
        isMobileNumberValid &&
        isTextMessageCheckedValid &&
        isEmailAlertsCheckedValid
    );

    setIsProfileFormSubmitted(true);

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isMobileNumberValid &&
      isTextMessageCheckedValid &&
      isEmailAlertsCheckedValid
    ) {
      // Submit the form or perform any other action
      const profileDetailData = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobileNumber,
        alertsForMobile: alertsForMobile,
        alertsForEmail: alertsForEmail,
        email: emailAddress,
        active: 1,
      };
      console.log(profileDetailData);
      dispatch(setDentistPersonalDetails(profileDetailData));
      // const response = AxiosPostHook(
      //   accessToken,
      //   profileDetailData,
      //   "personalDetails/save"
      // );
      // const _id = (await response).data[0]["_id"];
      // setProfileId(_id);
      console.log("Profile Form submitted");
      setActiveTab(nextString);
    } else {
      console.log("Form Not Valid");
    }
  };
  // FIRST FORM VALIDATIONS
  const validateFirstName = (value) => {
    return value?.trim().length > 0;
  };
  const validateLastName = (value) => {
    return value?.trim().length > 2;
  };
  const validateTimeZone = (value) => {
    return value?.trim().length > 0;
  };
  const validateMobileNumber = (value) => {
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(value);
  };

  const validateMobileNumberWithCountryCode = (value) => {
    const phoneRegex = /^(?:\+?\d{1,3})?[-.\s]?\d{7,15}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(value);
  };

  const validateTextMessageChecked = (value) => {
    if (value) {
      return true;
    } else {
      return false;
    }
  };
  const validateEmailAlertsChecked = (value) => {
    if (value) {
      return true;
    } else {
      return false;
    }
  };
  const validateTncChecked = (value) => {
    if (value) {
      return true;
    } else {
      return false;
    }
  };

  // FIRST FORM VALIDATIONS ENDS
  // clinic form submission
  const submitClinicInformation = (nextString) => {
    // Perform validation for each input
    const isRegistrationNumberValid =
      !isFieldRequired("registrationNumber") || validateRegistrationNumber(registrationNumber);
    const isRegAddressValid =
      !isFieldRequired("regAuthority") || validateRegAuthority(regAuthority);

    // Set overall form validity
    setIsProfileValid(isRegistrationNumberValid && isRegAddressValid);

    setIsClinicFormSubmitted(true);

    if (isRegistrationNumberValid && isRegAddressValid) {
      // Submit the form or perform any other action
      const clinicInformationData = {
        regNumber: registrationNumber,
        regAuthority: regAuthority,
      };
      dispatch(setDentistClinicDetails(clinicInformationData));
      console.log(clinicInformationData);
      console.log("Clinic Form submitted");
      setActiveTab(nextString);
    } else {
      console.log("Form Not Valid");
    }
  };
  // validation for clinic information
  const validateRegistrationNumber = (value) => {
    const phoneRegex = /^.{3,10}$/;
    return phoneRegex.test(value);
  };
  const validateRegAuthority = (value) => {
    const emailRegex = /^.{3,25}$/;
    return emailRegex.test(value);
  };
  // ends here

  // address form submission
  const submitAddressDetails = async (nextString) => {
    // Perform validation for each input
    const isAddressValid = !isFieldRequired("address") || validateAddress(address);
    const isCountryValid = !isFieldRequired("country") || country.length > 0;
    //validateCountryDropDown(country);
    const isPinCodeValid = !isFieldRequired("pinCode") || validatePinCode(pinCode);

    // Set overall form validity
    setIsAddressValid(isAddressValid && isCountryValid && isPinCodeValid);
    setIsAddressFormSubmitted(true);

    if (isAddressValid && isCountryValid && isPinCodeValid) {
      // Submit the form or perform any other action
      const addressData = {
        address: address,
        // country: selectedCountryOption,
        country: country,
        pincode: pinCode,
        coordinates: `${location.lat},${location.lng}`,
        // you can use location here to extract the lang and lat
        latitude: latitude,
        longitude: longitude,
      };

      dispatch(setDentistAddressDetails(addressData));
      // await saveDentistDetails(addressData);
      console.log(addressData);
      console.log("Address Form submitted");
      setActiveTab(nextString);
    } else {
      console.log("Form Not Valid");
    }
  };

  const submitSignatureDetails = async (nextString) => {
    await saveDentistDetails();
  };

  const saveDentistDetails = async () => {
    await Promise.all([
      savePersonalDetailsDt({
        accessToken,
        ...dentistDetails.personalDetails,
      }).unwrap(),
      saveClinicalDetails({
        accessToken,
        ...dentistDetails.clinicDetails,
      }).unwrap(),
      saveAddressDetails({
        accessToken,
        ...dentistDetails.addressDetails,
      }).unwrap(),
    ]);
    GetToast("Your details are secured successfully !! Happy exploring our portal !!", "success");
    navigate("/dashboard");
  };

  // validation for address details
  const validateCountry = (value) => {
    return value?.trim().length > 0;
  };
  const validateAddress = (value) => {
    return value?.trim().length > 0;
  };
  const validateCountryDropDown = () => {
    if (!selectedCountryOption) {
      return false;
    }
    return true;
  };
  const validatePinCode = (value) => {
    return value?.trim().length > 0;
  };
  // ends here
  // required fields here (do not delete) -> comment those fields which are not required fields
  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "mobileNumber",
      // "textMessages",
      // "emailAlerts",
      "tnc",
      "timezone",

      "registrationNumber",
      "regAuthority",

      "address",
      "country",
      "pinCode",
    ];

    return requiredFields.includes(fieldName);
  };
  const tabContentObject = {
    "Personal Details": (
      <PersonalDetails
        emailFromUserProfile={emailFromUserProfile}
        firstName={firstName}
        setFirstName={setFirstName}
        validateFirstName={validateFirstName}
        lastName={lastName}
        setLastName={setLastName}
        validateLastName={validateLastName}
        validateTimeZone={validateTimeZone}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        selectedTimeZone={selectedTimeZone}
        setSelectedTimeZone={setSelectedTimeZone}
        validateMobileNumberWithCountryCode={validateMobileNumberWithCountryCode}
        alertsForMobile={alertsForMobile}
        setAlertsForMobile={setAlertsForMobile}
        validateTextMessageChecked={validateTextMessageChecked}
        alertsForEmail={alertsForEmail}
        setAlertsForEmail={setAlertsForEmail}
        validateEmailAlertsChecked={validateEmailAlertsChecked}
        isProfileFormSubmitted={isProfileFormSubmitted}
        isFieldRequired={isFieldRequired}
        emailAddress={emailAddress}
      />
    ),
    "Clinical Information": (
      <ClinicInformation
        registrationNumber={registrationNumber}
        setRegistrationNumber={setRegistrationNumber}
        validateRegistrationNumber={validateRegistrationNumber}
        regAuthority={regAuthority}
        setRegAuthority={setRegAuthority}
        validateRegAuthority={validateRegAuthority}
        isClinicFormSubmitted={isClinicFormSubmitted}
        isFieldRequired={isFieldRequired}
      />
    ),
    "Address Details": (
      <AddressDetails
        address={address}
        setAddress={setAddress}
        validateAddress={validateAddress}
        selectedCountryOption={selectedCountryOption}
        setSelectedCountryOption={setSelectedCountryOption}
        countryOptions={countryOptions}
        validateCountryDropDown={validateCountryDropDown}
        pinCode={pinCode}
        setPinCode={setPinCode}
        validatePinCode={validatePinCode}
        isAddressFormSubmitted={isAddressFormSubmitted}
        locationDentist={locationDentist}
        setLocationDentist={setLocationDentist}
        setIsAddressFormSubmitted={setIsAddressFormSubmitted}
        isFieldRequired={isFieldRequired}
        location={location}
        setLocation={setLocation}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        country={country}
        setCountry={setCountry}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        validateCountry={validateCountry}
        isLoaded={isLoaded}
      />
    ),
    "Terms & Conditions": (
      <TermsAndConditons
        isFieldRequired={isFieldRequired}
        isTncChecked={isTncChecked}
        setIsTncChecked={setIsTncChecked}
        userSignature={userSignature}
        setUserSignature={setUserSignature}
        validateTncChecked={validateTncChecked}
      />
    ),
  };

  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
    setActiveAccordion((prevActiveAccordion) => (prevActiveAccordion === index ? -1 : index));
  };

  // Function to open the accordion based on activeTab
  const openAccordionBasedOnTab = () => {
    if (activeTab === "Personal Details") {
      setActiveAccordion(0);
    } else if (activeTab === "Clinical Information") {
      setActiveAccordion(1);
    } else if (activeTab === "Address Details") {
      setActiveAccordion(2);
    } else if (activeTab === "Terms & Conditions") {
      setActiveAccordion(3);
    }
  };

  useEffect(() => {
    openAccordionBasedOnTab();
  }, [activeTab]);

  const getEmailAddress = async () => {
    let userEmail = emailFromUserProfile;
    // Check if User Email exists otherwise fetch from profile data
    if (!userEmail) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await getProfile(accessToken).unwrap();
      userEmail = response["email"];
    }
    setEmailAddress(userEmail);
  };

  useEffect(() => {
    getEmailAddress();
  }, []);

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 750;

  return (
    // <div className="bg-[#E3DCF1] to-100% bg-opacity-[0.05] min-h-screen w-screen md:px-16 md:py-12 p-4 flex flex-col gap-8">
    //   <div className="flex bg-slate-500 justify-between items-center">
    //     <p className="font-medium text-[34px] leading-[30px] text-[#555]">
    //       {/* Select Service  --MSU */} Personal Details
    //     </p>
    //     {/* <div
    //       onClick={() => {
    //         saveDentistDetails(dentistDetails.addressDetails);
    //       }}
    //       style={{
    //         color: "rgb(255 126 0)",
    //         textDecoration: "underline",
    //         cursor: "pointer",
    //       }}
    //     >
    //       Skip this step
    //     </div> */}
    //     {/* <Link to={"/"}>
    //       <AiOutlineClose />
    //     </Link> */}
    //     <button
    //       onClick={() => handleLogout()}
    //       className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
    //     >
    //       Logout
    //     </button>
    //   </div>
    //   <div className="h-full bg-red-300 w-full flex flex-col gap-8">
    //     <div className="flex-1 flex md:flex-row flex-col drop-shadow-md rounded-md">
    //       <div className="TABS-NAV bg-pink-300 md:w-[220px] w-full md:py-0 py-4">
    //         <ul className="NAV flex flex-col justify-evenly items-center md:h-full h-fit md:gap-0 gap-8">
    //           {tabNamesArray.map((title, i) => {
    //             return (
    //               <li
    //                 key={title}
    //                 className="relative cursor-pointer w-full flex items-center justify-center"
    //                 onClick={() => handleTabSuper(title)}
    //               >
    //                 <div
    //                   className={
    //                     activeTab === title
    //                       ? "text-[#FF7E00] font-medium"
    //                       : "hoverClass"
    //                   }
    //                 >
    //                   {title}
    //                 </div>

    //                 <div
    //                   className={`${activeTab === title
    //                     ? "text-white bg-[#FF7E00]"
    //                     : "text-black bg-white"
    //                     } rounded-full flex justify-center items-center md:h-8 md:w-8 h-6 w-6 absolute md:-right-4 right-20 md:top-0  shadow-md`}
    //                 >
    //                   {i + 1}
    //                 </div>
    //               </li>
    //             );
    //           })}
    //         </ul>
    //       </div>
    //       <div className="TABS-OUTLET bg-green-300 flex-1 h-[100vh]">
    //         {Object.keys(tabContentObject).map((key) => {
    //           if (tabNamesArray.includes(key)) {
    //             return (
    //               <div key={key}>
    //                 {activeTab === key ? (
    //                   <>
    //                     <div key={key}>
    //                       <div className="">{tabContentObject[key]}</div>
    //                     </div>
    //                   </>
    //                 ) : null}
    //               </div>
    //             );
    //           }
    //           return null;
    //         })}
    //       </div>
    //     </div>
    //     <div className="flex items-center justify-end gap-8">
    //       <button
    //         onClick={() => handleBackTab(activeTab)}
    //         className={`${activeTab === tabNamesArray[0] ? "" : ""
    //           } p-3 rounded-full bg-white `}
    //         disabled={activeTab === tabNamesArray[0] ? true : false}
    //       >
    //         <MdKeyboardBackspace
    //           className={`text-xl ${activeTab === tabNamesArray[0]
    //             ? "text-opacity-20 text-[#555]"
    //             : ""
    //             } `}
    //         />
    //       </button>
    //       <button
    //         onClick={() => handleNextTab(activeTab)}
    //         className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
    //       >
    //         Next
    //       </button>
    //     </div>
    //   </div>
    // </div>

    //Upgraded UX
    <>
      {HideDiv ? (
        <div className="mt-7">
          <div className="mb-7 ">
            <div className="flex justify-center rounded-full card-gradient text-lg ml-3 p-4 mr-3 font-medium text-white bg-secondary ">
              {" "}
              Dentist Details
            </div>
          </div>
          <div className="">
            <AccordionItem
              title="Personal Details"
              isOpen={activeAccordion === 0}
              onToggle={() => toggleAccordion(0)}
              index={"1"}
            >
              <div className="Mainbar flex flex-col">
                <div className="bg-secondary h-[75%]">
                  <div>
                    <div className="TABS-OUTLET bg-secondary h-fit mt-0 flex-1">
                      {Object.keys(tabContentObject).map((key) => {
                        if (tabNamesArray.includes(key)) {
                          return (
                            <div key={key}>
                              {activeTab === key ? (
                                <>
                                  <div key={key}>
                                    <div className="">{tabContentObject[key]}</div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
                <div className="footer border-t bg-secondary pt-2">
                  <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                    <button
                      onClick={() => handleBackTab(activeTab)}
                      className={`${
                        activeTab === tabNamesArray[0] ? "" : ""
                      }  items-center rounded-md py-3 px-8 border-2 bg-white `}
                      disabled={activeTab === tabNamesArray[0] ? true : false}
                    >
                      <p
                        className={`text-xl ${
                          activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                        } `}
                      >
                        Back
                      </p>
                    </button>
                    <button
                      onClick={() => handleNextTab(activeTab)}
                      className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              title={"Clinical Information"}
              isOpen={activeAccordion === 1}
              onToggle={() => toggleAccordion("")}
              index={"2"}
            >
              <div className="Mainbar flex flex-col">
                <div className="bg-secondary h-[75%]">
                  <div>
                    <div className="TABS-OUTLET bg-secondary h-fit mt-0 flex-1">
                      {Object.keys(tabContentObject).map((key) => {
                        if (tabNamesArray.includes(key)) {
                          return (
                            <div key={key}>
                              {activeTab === key ? (
                                <>
                                  <div key={key}>
                                    <div className="">
                                      {tabContentObject["Clinical Information"]}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
                <div className="footer border-t bg-secondary pt-2">
                  <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                    <button
                      onClick={() => handleBackTab(activeTab)}
                      className={`${
                        activeTab === tabNamesArray[0] ? "" : ""
                      }  items-center rounded-md py-3 px-8 border-2 bg-white `}
                      disabled={activeTab === tabNamesArray[0] ? true : false}
                    >
                      <p
                        className={`text-xl ${
                          activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                        } `}
                      >
                        Back
                      </p>
                    </button>
                    <button
                      onClick={() => handleNextTab(activeTab)}
                      className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </AccordionItem>

            <AccordionItem
              title={"Address Details"}
              isOpen={activeAccordion === 2}
              onToggle={() => toggleAccordion("")}
              index={"3"}
            >
              <div className="Mainbar flex flex-col">
                <div className="bg-secondary h-[75%]">
                  <div>
                    <div className="TABS-OUTLET bg-secondary h-fit mt-0 flex-1">
                      {Object.keys(tabContentObject).map((key) => {
                        if (tabNamesArray.includes(key)) {
                          return (
                            <div key={key}>
                              {activeTab === key ? (
                                <>
                                  <div key={key}>
                                    <div className="">{tabContentObject["Address Details"]}</div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
                <div className="footer border-t bg-secondary pt-2">
                  <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                    <button
                      onClick={() => handleBackTab(activeTab)}
                      className={`${
                        activeTab === tabNamesArray[0] ? "" : ""
                      }  items-center rounded-md py-3 px-8 border-2 bg-white `}
                      disabled={activeTab === tabNamesArray[0] ? true : false}
                    >
                      <p
                        className={`text-xl ${
                          activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                        } `}
                      >
                        Back
                      </p>
                    </button>
                    <button
                      onClick={() => handleNextTab(activeTab)}
                      className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </AccordionItem>
          </div>
        </div>
      ) : (
        <div className="bg-[#E3DCF1] bg-opacity-[0.05] min-h-screen w-screen flex">
          <div className="side-card-gradient min-h-screen w-[25%] flex flex-col gap-7">
            <div className="mt-10 flex justify-center items-center">
              <div className="p-1 pl-4 w-[70%]">
                <img src={ToothLogo} />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="w-[70%] py-7 ">
                <p className="font-bold text-3xl p-1 px-7 text-white">Dentist</p>
              </div>{" "}
            </div>
            <div className="side-card-gradient mt-[-15px]">
              <div className="TABS-NAV w-full flex justify-center">
                <ul className="NAV flex flex-col justify-evenly items-center gap-12 p-5">
                  {tabNamesArray.map((title, i) => {
                    return (
                      <li
                        key={title}
                        className="w-full flex gap-4 items-center"
                        // onClick={() => handleTabSuper(title)}
                      >
                        <div className="relative flex justify-center items-center">
                          <div
                            className={`${
                              activeTab === title
                                ? "text-[#FF7E00] bg-white border"
                                : "text-white bg-[#FF7E00] border"
                            } rounded-full flex justify-center items-center h-8 w-8 md:top-0 shadow-md`}
                          >
                            {i + 1}
                          </div>
                          {i < tabNamesArray.length - 1 && (
                            <img className="absolute inset-y-0 pt-8" src={ConnectingLine} />
                          )}
                        </div>
                        <div
                          className={
                            activeTab === title ? "text-white font-medium" : "hoverClass text-white"
                          }
                        >
                          {title}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="Mainbar w-[75%] flex flex-col">
            <div className="h-[15%] relative">
              <img
                className="absolute inset-0 h-full w-full bg-black z-[9]"
                src={headerBG}
                alt="header background"
              />
              <p className="font-bold text-2xl pl-16 pt-12 pb-7 text-white relative z-10">
                {activeTab}
              </p>
            </div>

            <div className="bg-secondary h-[75%]">
              <div>
                <div className="TABS-OUTLET bg-secondary h-fit mt-0 flex-1">
                  {Object.keys(tabContentObject).map((key) => {
                    if (tabNamesArray.includes(key)) {
                      return (
                        <div key={key}>
                          {activeTab === key ? (
                            <>
                              <div key={key}>
                                <div className="">{tabContentObject[key]}</div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            <div className="footer border-t border-gray-400 pt-2 bg-secondary">
              <div className="flex items-center justify-end p-4 w-[90%] gap-8">
                <button
                  onClick={() => handleBackTab(activeTab)}
                  className={`${
                    activeTab === tabNamesArray[0] ? "" : ""
                  }  items-center rounded-md py-3 px-8 border-2 bg-white `}
                  disabled={activeTab === tabNamesArray[0] ? true : false}
                >
                  {/* <MdKeyboardBackspace
                className={`text-xl ${activeTab === tabNamesArray[0]
                  ? "text-opacity-20 text-[#555]"
                  : ""
                  } `}
              /> */}
                  <p
                    className={`text-xl ${
                      activeTab === tabNamesArray[0] ? "text-opacity-20 text-[#555]" : ""
                    } `}
                  >
                    Back
                  </p>
                </button>
                <button
                  onClick={() => handleNextTab(activeTab)}
                  className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DentistForm;

const PersonalDetails = ({
  emailFromUserProfile,
  firstName,
  setFirstName,
  validateFirstName,
  lastName,
  setLastName,
  emailAddress,
  validateLastName,
  mobileNumber,
  setMobileNumber,
  validateMobileNumberWithCountryCode,
  alertsForMobile,
  setAlertsForMobile,
  validateTextMessageChecked,
  alertsForEmail,
  setAlertsForEmail,
  validateEmailAlertsChecked,
  isFieldRequired,
  isProfileFormSubmitted,
  selectedTimeZone,
  setSelectedTimeZone,
  validateTimeZone,
}) => {
  // handles input changes in the form fields
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "firstName") setFirstName(value);
    if (name === "lastName") setLastName(value);
    if (name === "mobileNumber") setMobileNumber(value);
    if (name === "textMessages") setAlertsForMobile(checked);
    if (name === "emailAlerts") setAlertsForEmail(checked);
    if (name === "timeZone") setSelectedTimeZone(value);
  };

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 750;

  return (
    <div className="md:px-16 md:py-12 p-4">
      {/* {HideDiv &&
        <p className="font-medium text-[22px] leading-8 mb-4">Personal Details</p>} */}
      <div className="md:grid grid-cols-2 grid-rows-2 gap-x-8">
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"First Name "}
            errorMessage={"This field is required"}
            placeholder={"Enter your First Name"}
            isFieldRequired={isFieldRequired("firstName")}
            htmlFor={"firstName"}
            name={"firstName"}
            value={firstName}
            handleInputChange={handleInputChange}
            validationFunctionName={validateFirstName}
            isSubmitted={isProfileFormSubmitted}
          />
        </div>
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"Last Name "}
            errorMessage={"Please enter your Last Name"}
            placeholder={"Enter your Last Name"}
            isFieldRequired={isFieldRequired("lastName")}
            htmlFor={"lastName"}
            name={"lastName"}
            value={lastName}
            handleInputChange={handleInputChange}
            validationFunctionName={validateLastName}
            isSubmitted={isProfileFormSubmitted}
          />
        </div>
        <div>
          <NumberInputWithCountryCodeField
            labelColor={"#777"}
            labelText={"Mobile Number "}
            errorMessage={"Please enter your Mobile Number"}
            placeholder={"Enter your Mobile Number"}
            isFieldRequired={isFieldRequired("mobileNumber")}
            htmlFor={"mobileNumber"}
            name={"mobileNumber"}
            value={mobileNumber}
            handleInputChange={setMobileNumber}
            validationFunctionName={validateMobileNumberWithCountryCode}
            isSubmitted={isProfileFormSubmitted}
          />
        </div>
        <div>
          <TextInputField
            labelColor={"#777"}
            labelText={"Email "}
            errorMessage={"Please enter your email"}
            placeholder={"Enter your Email"}
            isFieldRequired={false}
            htmlFor={"email"}
            name={"email"}
            value={emailAddress}
            handleInputChange={() => {}}
            validationFunctionName={validateLastName}
            isSubmitted={isProfileFormSubmitted}
            disabled={true}
          />
        </div>
        <div>
          {/* <label
            htmlFor="readOnlyEmail"
            className="text-[16px] leading-4 text-[#777] "
          >
            Email
          </label>
          <input
            id="readOnlyEmail"
            name="readOnlyEmail"
            className="border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-4 rounded-md"
            type="text"
            value={emailFromUserProfile}
            readOnly
          /> */}
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-8 md:p-0 px-4 py-2">
        <div className="">
          <div className="relative flex items-center">
            <ActiveToggleButton
              currentState={alertsForMobile}
              setState={(value) => setAlertsForMobile(value ? true : false)}
            />
            <label className="text-[16px] leading-4 text-white ml-2" htmlFor="textMessages">
              Allow Text Messages to Send Alerts{" "}
              {isFieldRequired("textMessages") ? <span className="text-red-500">*</span> : ""}
            </label>
            {isFieldRequired("textMessages") &&
              validateTextMessageChecked(alertsForMobile) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500  absolute -bottom-6 text-[15px] left-0  ">
                  Please Check This to Continue.
                </p>
              )}
          </div>
        </div>
        <div className="">
          <div className="relative flex items-center">
            <ActiveToggleButton
              currentState={alertsForEmail}
              setState={(value) => setAlertsForEmail(value ? true : false)}
            />
            <label className="text-[16px] leading-4 text-white ml-2" htmlFor="emailAlerts">
              Allow Emails to Send Alerts{" "}
              {isFieldRequired("emailAlerts") ? <span className="text-red-500">*</span> : ""}
            </label>
            {isFieldRequired("emailAlerts") &&
              validateEmailAlertsChecked(alertsForEmail) === false &&
              isProfileFormSubmitted && (
                <p className="text-red-500  absolute -bottom-6 text-[15px] left-0  ">
                  Please Check This to Continue.
                </p>
              )}
          </div>
        </div>
      </div>
      <div className="mt-2 mb-4 relative">
        <p className="text-[16px] leading-4 text-gray-500">
          (Both options need to be selected to allow effective communication)
        </p>
      </div>
      <div className="relative">
        <TimeZoneInputField
          labelColor={"#777"}
          labelText={"Time Zone"}
          errorMessage={"Please Select a Timezone"}
          placeholder={"Select Timezone"}
          isFieldRequired={isFieldRequired("timezone")}
          htmlFor={"timeZone"}
          name={"timeZone"}
          value={selectedTimeZone}
          handleInputChange={handleInputChange}
          validationFunctionName={validateTimeZone}
          isSubmitted={isProfileFormSubmitted}
        />
      </div>
    </div>
  );
};
const ClinicInformation = ({
  registrationNumber,
  setRegistrationNumber,
  validateRegistrationNumber,
  regAuthority,
  setRegAuthority,
  validateRegAuthority,
  isClinicFormSubmitted,
  isFieldRequired,
}) => {
  // handles input changes in the form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "registrationNumber") setRegistrationNumber(value);
    if (name === "regAuthority") setRegAuthority(value);
  };

  return (
    <div className="md:px-16 md:py-12 p-4">
      {/* {HideDiv ?
        <p className="font-medium text-[22px] leading-8 mb-4">
          Clinical Information
        </p> : null} */}
      <div className="md:grid grid-cols-2 grid-rows-1 gap-x-8">
        <div>
          <NumberInputField
            labelColor={"#777"}
            labelText={"Registration Number"}
            errorMessage={"Registration Number must be min 3 digits and max 10"}
            placeholder={"Enter your Registration Number"}
            isFieldRequired={isFieldRequired("registrationNumber")}
            htmlFor={"registrationNumber"}
            name={"registrationNumber"}
            value={registrationNumber}
            handleInputChange={handleInputChange}
            validationFunctionName={validateRegistrationNumber}
            isSubmitted={isClinicFormSubmitted}
          />
        </div>
        <div>
          <EmailInputField
            labelColor={"#777"}
            labelText={"Registration Authority"}
            errorMessage={"Enter valid Authority"}
            placeholder={"Enter your Registration Authority"}
            isFieldRequired={isFieldRequired("regAuthority")}
            htmlFor={"regAuthority"}
            name={"regAuthority"}
            value={regAuthority}
            handleInputChange={handleInputChange}
            validationFunctionName={validateRegAuthority}
            isSubmitted={isClinicFormSubmitted}
          />
        </div>
      </div>
    </div>
  );
};
const AddressDetails = ({
  address,
  setAddress,
  validateAddress,
  countryOptions,
  selectedCountryOption,
  setSelectedCountryOption,
  validateCountryDropDown,
  pinCode,
  setPinCode,
  validatePinCode,
  locationDentist,
  setLocationDentist,
  isAddressFormSubmitted,
  isFieldRequired,
  location,
  setLocation,
  setLatitude,
  setLongitude,
  selectedLocation,
  country,
  setCountry,
  setSelectedLocation,
  validateCountry,
  isLoaded,
}) => {
  // handles input changes in the form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "address") {
      setAddress(value);
      setSelectedLocation(null);
    }
    if (name === "country") setCountry(value);
    if (name === "pinCode") setPinCode(value);
  };

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 750;

  return (
    <div className="md:px-16 md:py-2 p-4">
      {/* {HideDiv ?
        <p className="font-medium text-[22px] leading-8 mb-4">Address Details</p> : null} */}
      <div className="flex flex-col gap-0">
        <div className=" col-span-2">
          {/* <div>
            <TextInputField
              labelColor={"#777"}
              labelText={"Address"}
              errorMessage={"Please enter your address"}
              placeholder={"Enter your Address"}
              isFieldRequired={isFieldRequired("address")}
              htmlFor={"address"}
              name={"address"}
              value={address}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAddress}
              isSubmitted={isAddressFormSubmitted}
            />
          </div> */}
        </div>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <div className=" col-span-2">
              <LocationInputV2
                setValue={setAddress}
                placeholder={"Search an address location"}
                name={"addressSearch"}
                setCountry={setCountry}
                setLatitude={setLatitude}
                setLocation={setLocation}
                setLongitude={setLongitude}
                setPinCode={setPinCode}
              />
            </div>
            <div className="col-span-2 row-span-2 mb-2">
              <GoogleMap
                mapContainerClassName="h-[140px]"
                center={location}
                zoom={10}
                onClick={(e) => {
                  setLatitude(e.latLng.lat());
                  setLongitude(e.latLng.lng());
                  setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                }}
                clickableIcons={true}
              >
                <Marker position={location} />
              </GoogleMap>
            </div>
          </>
        )}

        <div className="col-span-2 row-span-2">
          <TextAreaInputField
            labelColor={"#777"}
            labelText={"Address "}
            errorMessage={"Enter Valid Address"}
            placeholder={"Write here"}
            isFieldRequired={isFieldRequired("address")}
            htmlFor={"address"}
            name={"address"}
            value={address}
            handleInputChange={handleInputChange}
            validationFunctionName={validateAddress}
            isSubmitted={isAddressFormSubmitted}
            rows={4}
            cols={5}
          />
        </div>

        <div className="flex gap-8">
          <div className="col-span-1">
            {/* <div>
            <DropdownField
              labelColor={"#777"}
              labelText={"Country"}
              errorMessage={"Please Select a Country"}
              placeholder={"Select a Country"}
              isFieldRequired={isFieldRequired("country")}
              htmlFor={"country"}
              name={"country"}
              value={selectedCountryOption}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCountryDropDown}
              isSubmitted={isAddressFormSubmitted}
              options={countryOptions}
            />
          </div> */}
            <TextInputField
              labelColor={"#777"}
              labelText={"Country"}
              errorMessage={"Please enter a country name"}
              placeholder={"Country name"}
              htmlFor={"country"}
              name={"country"}
              value={country}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCountry}
              isSubmitted={isAddressFormSubmitted}
              isFieldRequired={isFieldRequired("country")}
            />
          </div>
          <div className="col-span-1">
            <NumberInputField
              labelColor={"#777"}
              labelText={"Postal code (Zip code/Pin code)"}
              errorMessage={"Please Enter Valid Pin Code"}
              placeholder={"Please Enter your Pin Code"}
              isFieldRequired={isFieldRequired("pinCode")}
              htmlFor={"pinCode"}
              name={"pinCode"}
              value={pinCode}
              handleInputChange={handleInputChange}
              validationFunctionName={validatePinCode}
              isSubmitted={isAddressFormSubmitted}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const TermsAndConditons = ({
  isFieldRequired,
  isTncChecked,
  setIsTncChecked,
  validateTncChecked,
  userSignature,
  setUserSignature,
}) => {
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false);
  const signPadRef = useRef();

  const clearCanvas = () => {
    signPadRef.current.clear();
  };

  const updateSignature = () => {
    let signature = signPadRef.current.getTrimmedCanvas().toDataURL("image/png");
    setUserSignature(signature);
    return signature;
  };

  const getTncPdfFormat = (tempSignature) => {
    return (
      <div className="w-[500px] font-medium text-black bg-white text-xl text-justify font-[sans-serif]">
        <h1 className="text-center text-xl font-bold">Terms & Conditions</h1>
        <ul className="list-none w-full text-base flex flex-col gap-2 py-3 px-3 pl-5">
          <li>
            • All Orthodontic treatment planning services are ultimately to be evaluated and
            accepted by the treating Dentist. Progression of patient treatment implies that the
            treating Dentist has made an independent assessment of the treatment plan, considered
            all options available and is conscious of the risks involved.
          </li>
          <li>
            • The portal does not accept any liability for its treatment planning services and all
            responsibility is assumed only by the treating Dentist.
          </li>
          <li>
            • The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
          <li>
            • The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
          <li>
            • The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
          <li>
            • The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
        </ul>
        {tempSignature && (
          <div className="flex justify-end">
            <img src={tempSignature} alt="User Signature" className="md:w-40 mt-3" />
          </div>
        )}
      </div>
    );
  };

  const generatePDFDocument = (tempSignature) => {
    var doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [780, 540],
      hotfixes: ["px_scaling"],
    });
    doc.html(ReactDOMServer.renderToStaticMarkup(getTncPdfFormat(tempSignature)), {
      callback: function (doc) {
        doc.save("Tnc_agreement_" + new Date().getTime());
      },
      margin: [20, 20, 25, 20],
      width: 500,
      windowWidth: 500,
    });
  };

  return (
    <div className="md:px-16 md:py-12 h-full p-4 flex flex-col justify-between">
      <div className="relative">
        <div className="">
          <input
            type="checkbox"
            name="tnc"
            id="tnc"
            checked={isTncChecked}
            onChange={(e) => {
              if (e.currentTarget.checked) {
                setNotificationModalIsOpen(true);
              } else {
                setIsTncChecked(false);
                setUserSignature(null);
              }
            }}
            required={isFieldRequired("tnc")}
          />
          <label className="text-[16px] leading-4 text-[#868686] ml-2" htmlFor="tnc">
            Sign Document to accept terms and conditions{" "}
            {isFieldRequired("tnc") ? <span className="text-red-500">*</span> : ""}
          </label>
          {isFieldRequired("tnc") && validateTncChecked(isTncChecked) === false && (
            <p className="text-red-500 text-[15px]">Please Check This to Continue.</p>
          )}
        </div>
        <ul className="max-h-[25vh] my-4 overflow-y-auto list-[circle] text-base text-white flex flex-col gap-2 py-3 px-3 pl-5 border rounded-md">
          <li>
            All Orthodontic treatment planning services are ultimately to be evaluated and accepted
            by the treating Dentist. Progression of patient treatment implies that the treating
            Dentist has made an independent assessment of the treatment plan, considered all options
            available and is conscious of the risks involved.
          </li>
          <li>
            The portal does not accept any liability for its treatment planning services and all
            responsibility is assumed only by the treating Dentist.
          </li>
          <li>
            The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
          <li>
            The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
          <li>
            The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
          <li>
            The portal also cannot be responsible for treatment not progressing according to the
            treatment plan as treatment outcomes are multi-factorial and are affected by patient
            compliance as well.
          </li>
        </ul>
        {userSignature && (
          <div className="">
            <img src={userSignature} alt="User Signature" className="bg-white md:w-52 w-[50%]" />
          </div>
        )}
        <Modal
          isOpen={notificationModalIsOpen}
          onRequestClose={() => {
            setNotificationModalIsOpen(false);
          }}
          contentLabel="Custom Modal"
          className="custom-modal"
          // style={customStyles}
          ariaHideApp={false}
        >
          <>
            <div className="MODAL-BODY flex flex-col h-full">
              <button
                className="w-full text-right"
                onClick={() => setNotificationModalIsOpen(false)}
              >
                <AiOutlineClose className="ml-auto text-white" />
              </button>
              <p className="text-[26px] font-medium text-white leading-8 mb-6">
                Sign Terms & Conditions
              </p>
              <div className="MODAL-TAB-SECTION flex-1 flex flex-col">
                <SignatureCanvas
                  canvasProps={{ className: "bg-white", height: 400, onChange: updateSignature }}
                  ref={signPadRef}
                />
                <div className="flex items-center justify-end gap-4 pt-4 mt-auto">
                  <button
                    onClick={clearCanvas}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Try Again
                  </button>
                  <button
                    onClick={() => {
                      let tempSignature = updateSignature();
                      setIsTncChecked(true);
                      setNotificationModalIsOpen(false);
                      generatePDFDocument(tempSignature);
                    }}
                    className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#FF7E00] rounded-xl"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        </Modal>
      </div>
    </div>
  );
};
