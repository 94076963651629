import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import html2canvas from "html2canvas";
import Modal from "react-modal";
import jsPDF from "jspdf";

import { LoginButton } from "../Login/Login";
import Notification from "../../Components/Notification/Notification";

import { OPTIONS_VALUE, ORDER_STAGE } from "../../constants/orderForms";

import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import imagePlaceholderIcon from "../../Assets/images/imagePlaceholderIcon.png";
import { isImageFormatDisplayable } from "../../Utils/utility";

function ImplantOrderConfirmation({
  orderFor,
  orderObjId,
  userInfo,
  userRole,
  setActiveTab,
  selectedServiceCategory,
  setIsDisclaimerAccepted,
  isOrderPlaced = false,
  placeOrderHandler,
}) {
  return (
    <div className="flex w-full">
      <OrderConfirmationTab
        orderObjId={orderObjId}
        userRole={userRole}
        userInfo={userInfo}
        setActiveTab={setActiveTab}
        selectedServiceCategory={selectedServiceCategory}
        setIsDisclaimerAccepted={setIsDisclaimerAccepted}
        isOrderPlaced={isOrderPlaced}
        placeOrderHandler={placeOrderHandler}
      />
    </div>
  );
}

export default ImplantOrderConfirmation;
export const OrderConfirmationTab = ({
  userInfo,
  userRole,
  orderObjId,
  setActiveTab,
  selectedServiceCategory,
  setIsDisclaimerAccepted,
  isOrderPlaced,
  placeOrderHandler,
}) => {
  const navigate = useNavigate();

  const { dentalImplantOrderDetails } = useSelector((state) => state.orderFormDetails);
  const { user, isAuthenticated } = useAuth0();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  console.log("dentalImplantOrderDetails", dentalImplantOrderDetails);
  console.log("selectedServiceCategory", selectedServiceCategory);

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  const pdfRef = useRef();

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width * 0.264583; // Convert pixels to mm (1 px = 0.264583 mm)
      const pdfHeight = canvas.height * 0.264583; // Convert pixels to mm

      const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]); // Create a PDF with custom page size

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Add the image to the PDF

      pdf.save("download.pdf");
    });
  };

  const getServiceNames = (services) => {
    const serviceNames = [];
    if (selectedServiceCategory?.catservices?.length > 0 && services?.length > 0) {
      services?.every((item) => {
        selectedServiceCategory?.catservices?.every((serItem) => {
          if (item?.serviceObjId === serItem?.serviceObjId) {
            serviceNames.push(serItem?.serviceName);
            return false;
          }
          return true;
        });
        return true;
      });
    }
    return serviceNames.join(", ");
  };

  return (
    <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-black border border-[#dfdfdf]">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full bg-secondary p-4 rounded-t-xl">
        <p className="font-medium text-[34px] text-white">Implant Restoration Order</p>
        {!isOrderPlaced && (
          <>
            <div onClick={downloadPDF} className="flex gap-4 items-center">
              <img src={printerIcon} alt="" />
              <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
                <img src={exitIcon} alt="" />
                Export
              </button>
            </div>
            <div className="flex items-center md:justify-end gap-4 flex-wrap">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setActiveTab(ORDER_STAGE.DISCLAIMER);
                  setIsDisclaimerAccepted(false);
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Back
              </button>
              <button
                onClick={() => {
                  placeOrderHandler();
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
              >
                Get Quote
              </button>
            </div>
          </>
        )}
      </div>

      <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full bg-secondary rounded-b-xl px-4 w-full">
        <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md">
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className=" text-white md:block hidden ">Service Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className="text-white md:block hidden ">Order Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            {isOrderPlaced ? (
              <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
            ) : (
              <div className="bg-orange-500 h-8 w-8 rounded-full border text-[#fff] font-medium flex items-center justify-center">
                3
              </div>
            )}

            <p className="text-white font-semibold md:block hidden ">Confirmation</p>
          </div>
        </div>
        {isOrderPlaced ? (
          <div className="flex-1 flex flex-col justify-between h-full">
            <div className="h-full w-full flex-1 flex flex-col justify-center items-center gap-2">
              <img src={orderPlacedIcon} alt="" />
              <p className="text-2xl text-white font-semibold">Order Placed Successfully</p>
              <p className="text-[17px] text-white">
                Order has been sent for review will get back to you with the best possible quote in
                48 hours
              </p>
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full bg-secondary p-3" ref={pdfRef}>
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient Initials</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.patientInitials || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient details have been anonymized</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.detailsAnonymized ? "Yes" : "No"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Date of Birth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.dob || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Gender</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.gender || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Shipping Address</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.address || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Country</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.country || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Postal code (Zip code/Pin code)</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.patientDetails?.pinCode || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">The patient has any missing teeth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.caseDetails?.hasMissingTeeth || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Missing Teeths</p>
                  <p className="text-[#555] font-medium mt-2">
                    {dentalImplantOrderDetails?.caseDetails?.missingTeethSelectedTeeth?.join(
                      ", "
                    ) || "NA"}
                  </p>
                </div>

                {dentalImplantOrderDetails?.caseDetails?.services?.length > 0 && (
                  <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-xl text-white text-center uppercase">Services Selected</p>
                  </div>
                )}
              </div>
              {dentalImplantOrderDetails?.caseDetails?.services?.map((service) => {
                const serviceDetails = selectedServiceCategory?.catservices?.find(
                  (d) => d?.serviceObjId === service?.serviceObjId
                );
                console.log("serviceDetails", serviceDetails);
                if (serviceDetails?.serviceObjId) {
                  return (
                    <div
                      key={"serviceItem-" + serviceDetails?.serviceObjId}
                      className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
                    >
                      <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                        <p className="text-[15px] text-white font-semibold">Service Name</p>
                        <p className="text-primary font-medium mt-2 uppercase">
                          {serviceDetails?.serviceName}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Implant sites Teeths</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.teeth?.join(", ") || "NA"}
                        </p>
                      </div>
                      {[
                        "Single or multiple implant crowns (screw retained)",
                        "Single or multiple implant crowns (cemented)",
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            {[
                              "Implant bridge (screw retained)",
                              "Implant bridge (cemented)",
                              "Implant bridge with gingiva (screw retained)",
                            ].includes(serviceDetails?.serviceName)
                              ? "Type of Bridge Retainer and Pontic"
                              : "Type of Crown"}
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.typeOfCrown?.length > 0
                              ? service?.typeOfCrown.join(", ")
                              : "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Single or multiple implant crowns (screw retained)",
                        "Implant bridge (screw retained)",
                        "Implant bridge with gingiva (screw retained)",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            Would you like a single restoration design (Ti base and restoration) or
                            a split restoration design (Ti base and tissue level abutment and
                            restoration)?
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.restorationDesign || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Custom abutments",
                        "Single or multiple implant crowns (screw retained)",
                        "Single or multiple implant crowns (cemented)",
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                        "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                        "All on X hybrid with prep style posts for crowns",
                        "All on X custom framework",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Cement Gap (µ microns)</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.cementGap || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Single or multiple implant crowns (screw retained)",
                        "Single or multiple implant crowns (cemented)",
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                        "All on X hybrid with prep style posts for crowns",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Interproximal Contacts</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.interproximalContacts || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Single or multiple implant crowns (screw retained)",
                        "Single or multiple implant crowns (cemented)",
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                        "Implant bar substructure for over denture",
                        "All on X Hybrid (monolithic zirconia)",
                        "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                        "All on X hybrid with prep style posts for crowns",
                        "All on X custom framework",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Occlusal Contacts</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.occlusalContacts || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                        "Implant bar substructure for over denture",
                        "All on X Hybrid (monolithic zirconia)",
                        "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                        "All on X hybrid with prep style posts for crowns",
                        "All on X custom framework",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            {[
                              "Implant bar substructure for over denture",
                              "All on X Hybrid (monolithic zirconia)",
                              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                              "All on X hybrid with prep style posts for crowns",
                              "All on X custom framework",
                            ].includes(serviceDetails?.serviceName)
                              ? "Tissue Interface Design"
                              : "Pontic Design"}
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.ponticDesign || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          Do you need to see a design preview?
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.needDesignPreview || "NA"}
                        </p>
                      </div>
                      {[
                        "Single or multiple implant crowns (screw retained)",
                        "Single or multiple implant crowns (cemented)",
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                        "Implant bar substructure for over denture",
                        "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                        "All on X hybrid with prep style posts for crowns",
                        "All on X custom framework",
                      ].includes(serviceDetails?.serviceName) && (
                        <>
                          <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                            <p className="text-[15px] text-white">
                              {[
                                "Implant bar substructure for over denture",
                                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                                "All on X custom framework",
                              ].includes(serviceDetails?.serviceName)
                                ? "The substructure is always designed to be milled in our designs. With reference to the superstructure, will you be"
                                : serviceDetails?.serviceName ===
                                  "All on X hybrid with prep style posts for crowns"
                                ? "The substructure is always designed to be milled in our designs. With reference to the crowns, will you be"
                                : "Will you be"}
                            </p>
                            <p className="text-[#555] font-medium mt-2">
                              {service?.processType || "NA"}
                            </p>
                          </div>

                          {service?.processType === OPTIONS_VALUE.PRINTING && (
                            <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                              <p className="text-[15px] text-white">
                                If you are printing what restorative space do you prefer? (µ
                                microns)
                              </p>
                              <p className="text-[#555] font-medium mt-2">
                                {service?.processTypePreferredRestorativeSpace || "NA"}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Design file to be provided?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.designFileNeeded || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Preferred design software?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.preferredSoftware || "NA"}
                        </p>
                      </div>
                      {[
                        "Custom abutments",
                        "Single or multiple implant crowns (screw retained)",
                        "Single or multiple implant crowns (cemented)",
                        "Implant bridge (screw retained)",
                        "Implant bridge (cemented)",
                        "Implant bridge with gingiva (screw retained)",
                        "Implant bar substructure for over denture",
                        "All on X Hybrid (monolithic zirconia)",
                        "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                        "All on X hybrid with prep style posts for crowns",
                        "All on X custom framework",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            {[
                              "Implant bar substructure for over denture",
                              "All on X Hybrid (monolithic zirconia)",
                              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                              "All on X hybrid with prep style posts for crowns",
                              "All on X custom framework",
                            ].includes(serviceDetails?.serviceName)
                              ? "Restorations, superstructures and substructures are normally provided as .STLs, Please advise if a construction.info file is desired."
                              : "Restorations are normally provided as .STLs, Please advise if a construction.info file is desired."}
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.constructionFileNeeded || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Is a printable model desired?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.printableModelRequired || "NA"}
                        </p>
                      </div>
                      {service?.printableModelRequired === "Yes" && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            Should the crown and bridge model include dies?
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.printableModelincludeDies || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">
                          If a certain restoration lacks occlusal clearance? Please advise your
                          preferred course of action:{" "}
                        </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.restorationLacksOC || "NA"}
                        </p>
                      </div>
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Additional Instructions </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.additionalInstructions || "NA"}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}

              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE md:col-span-3 pb-4 border-b uppercase border-1 border-dotted border-white border-opacity-20">
                  <p className="text-xl text-white text-center">Uploads</p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Photographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.photographs)?.map(
                      function (key, index) {
                        if (dentalImplantOrderDetails?.uploadFiles?.photographs[key]?.s3Url) {
                          return (
                            <div
                              key={"photographItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  dentalImplantOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    dentalImplantOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                                  )
                                    ? dentalImplantOrderDetails?.uploadFiles?.photographs[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.photographs || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Radiographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.radiographs)?.map(
                      function (key, index) {
                        if (dentalImplantOrderDetails?.uploadFiles?.radiographs[key]?.s3Url) {
                          return (
                            <div
                              key={"radiographItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  dentalImplantOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    dentalImplantOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                                  )
                                    ? dentalImplantOrderDetails?.uploadFiles?.radiographs[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.radiographs || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Intra Oral Scans</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.intraOralScans)?.map(
                      function (key, index) {
                        if (dentalImplantOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url) {
                          return (
                            <div
                              key={"intraOralScansItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  dentalImplantOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    dentalImplantOrderDetails?.uploadFiles?.intraOralScans[key]
                                      ?.s3Url
                                  )
                                    ? dentalImplantOrderDetails?.uploadFiles?.intraOralScans[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.intraOralScans || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Raw DICOM Data</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.rawDicomData)?.map(
                      (key, index) => {
                        if (dentalImplantOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  dentalImplantOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    dentalImplantOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                                  )
                                    ? dentalImplantOrderDetails?.uploadFiles?.rawDicomData[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.rawDicomData || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Additional Files</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.additionalFiles)?.map(
                      (key, index) => {
                        if (dentalImplantOrderDetails?.uploadFiles?.additionalFiles?.[key]?.s3Url) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  dentalImplantOrderDetails?.uploadFiles?.additionalFiles?.[key]
                                    ?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    dentalImplantOrderDetails?.uploadFiles?.additionalFiles[key]
                                      ?.s3Url
                                  )
                                    ? dentalImplantOrderDetails?.uploadFiles?.additionalFiles[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(dentalImplantOrderDetails?.uploadFiles?.additionalFiles || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                {isImageModalOpen && (
                  <div className="image-modal blur-md">
                    <Modal
                      isOpen={isImageModalOpen}
                      onRequestClose={() => setIsImageModalOpen(false)}
                      contentLabel="Custom Modal"
                      className="image-modal !bg-black/80"
                      // style={customStyles}
                      ariaHideApp={false}
                    >
                      <div
                        className="MODAL-BODY flex flex-col h-full"
                        onClick={() => closeImageModal()}
                      >
                        <button className="w-full text-right">
                          <AiOutlineClose className="ml-auto text-white" />
                        </button>
                        <img
                          className={`${
                            isImageFormatDisplayable(selectedImage) ? "h-[90%]" : "h-[85%]"
                          } w-full object-contain`}
                          src={
                            isImageFormatDisplayable(selectedImage)
                              ? selectedImage
                              : imagePlaceholderIcon
                          }
                          onClick={(e) => e.stopPropagation()}
                          alt=""
                        />
                        {!isImageFormatDisplayable(selectedImage) && (
                          <p className="mt-3 font-medium text-white text-lg text-center">
                            File Preview not available
                          </p>
                        )}
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
