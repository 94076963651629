import React, { useEffect, useState, Fragment } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  superAdminIconArray,
  spIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import bell from "../../Assets/images/bellIcon.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import circle from "../../Assets/images/circle.png";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import { useNavigate } from "react-router-dom";
import dummyImage from "../../Assets/images/dummyImage.png";
import ServiceCarouselTile from "../../Components/UserManagement-Comps/ServiceCarouselTile";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import ServiceProviderCard from "../../Components/OrderAdmin-comps/ServiceProviderCard";
import ticket from "../../Assets/icons/ticket.svg";
import replyIcon from "../../Assets/icons/replyIcon.svg";
import { AiOutlineArrowUp, AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import TextInputField from "../../Components/InputField-Comps/TextInputField";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import StageBox from "../../Components/Order-Comps/StageBox";
import rejectedIcon from "../../Assets/icons/rejectedIcon.svg";
import completeIcon from "../../Assets/icons/completeIcon.png";
import { useLocation } from "react-router-dom";
import {
  useGetOrderByIdMutation,
  useReadCommOrdersMutation,
  useSaveCommOrdersMutation,
  useUpdateCommOrdersMutation,
  useUpdateOrderByIdMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { Tooltip } from "react-tooltip";
import AttachUpload from "../../Components/Order-Comps/AttachUpload";
import ConcernCard from "../../Components/Order-Comps/ConcernCard";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";

const displayOptions = require("../../Utils/orders-form1-options.json");

export const OrderDetailSummary = ({
  data,
  setIsStepOneChecked,
  isStepOneChecked,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [getOrderById] = useGetOrderByIdMutation();
  const [orderDetails, setOrderDetails] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const getOrderData = async (accessToken, orderId) => {
    const resp = await getOrderById({ accessToken, orderId }).unwrap();
    if (resp) {
      // debugger
      setOrderDetails(resp.data[0]);
      // debugger
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getOrderData(accessToken, data.orderObjId);
    }
  };

  useEffect(() => {
    console.log("useEffect services working --- ");
    readOrderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data.statusCode, "statusCode");
  return (
    // "Error in performing this action - reducer failed"
    <>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 bg-secondary">
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Patient Initials
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {orderDetails?.orderpatientdetails?.[0]?.patientInitials}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Date of Birth
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {orderDetails?.orderpatientdetails?.[0]?.dob}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Gender
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {orderDetails?.orderpatientdetails?.[0]?.gender}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Options
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {orderDetails?.orderpatientdetails?.[0]?.options}
          </p>
        </div>
        <div
          className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Clinical Conditions
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {Object.keys(orderDetails?.orderclinicalconditions?.[0] || {}).map((element, index) =>
              orderDetails?.orderclinicalconditions?.[0][element] ? (
                <span key={index}>
                  {displayOptions.options[0][element]}
                  <br /> {/* Add a line break */}
                </span>
              ) : null
            )}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Patient Concern{" "}
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {orderDetails?.orderpatientdetails?.[0]?.patientConcerns}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Upload photographs
          </p>
          <div
            className="text-[#555]
 font-medium mt-2 flex flex-row gap-2"
          >
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "uploadData") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Intra Oral Scans
          </p>
          <div
            className="text-[#555]
 font-medium mt-2 flex flex-row gap-2"
          >
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "internalScan") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Intra Oral Scans
          </p>
          <div
            className="text-[#555]
 font-medium mt-2 flex flex-row gap-2"
          >
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "internalScan2") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div
          className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Treatment Goals
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {Object.keys(orderDetails?.ordertreatmentgoals?.[0] || {}).map((element, index) =>
              orderDetails?.ordertreatmentgoals?.[0][element] ? (
                <span key={index}>
                  {displayOptions.treatmentGoals[0][element]}
                  {displayOptions.midline[0][element]}
                  <br /> {/* Add a line break */}
                </span>
              ) : (
                ""
              )
            )}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Tooth Movement Restrictions (UTN)
          </p>
          <div
            className="text-[#555]
 font-medium mt-2 flex flex-row gap-2"
          >
            {orderDetails?.ordertooth?.map((element, index) => (
              <span key={index}>
                {element.toothNumber}
                <br /> {/* Add a line break */}
              </span>
            ))}
          </div>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Inter Proximal Reduction (IPR)
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementInternal[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Overjet Correction
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementOverJet[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Overbite Correction
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementOverBite[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Crossbite Correction
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementCrossBite[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div
          className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20"
        >
          <p
            className="text-[15px] text-white
"
          >
            Special Instrcutions
          </p>
          <p
            className="text-[#555]
 font-medium mt-2"
          >
            {orderDetails?.ordertoothmovement?.[0].specialInstructions}
          </p>
        </div>

        {isImageModalOpen && (
          <div className="image-modal">
            <Modal
              isOpen={isImageModalOpen}
              onRequestClose={() => setIsImageModalOpen(false)}
              contentLabel="Custom Modal"
              className="image-modal"
              // style={customStyles}
              ariaHideApp={false}
            >
              <>
                <div className="MODAL-BODY flex flex-col h-full">
                  <button className="w-full text-right" onClick={() => closeImageModal()}>
                    <AiOutlineClose className="ml-auto" />
                  </button>
                  <img className="h-full w-full object-contain" src={selectedImage} alt="" />
                </div>
              </>
            </Modal>
          </div>
        )}
      </div>
      <div className="mt-4">
        {data.statusCode === "113" ? (
          <div className="w-full">
            <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Cancel
              </button>

              <button
                onClick={() => {
                  setIsStepOneChecked(true);
                  setActiveTab("Payment Method");
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
              >
                Continue
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export const PaymentMethod = ({ data, setActiveTab, setIsStepTwoChecked }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [updateOrderById] = useUpdateOrderByIdMutation();

  const priorityFee = (data.portalPrice * 10) / 100;

  const handlePaymentSuccess = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await updateOrderById({
      accessToken,
      orderId: data?.orderObjId,
      currentStatus: "5",
    });
    if (resp) {
      GetToast("Order Payment Successful");
    }
  };

  return (
    <>
      <div className="font-semibold h-full flex-1 flex flex-col">
        <p className="text-xl mb-4">Payment</p>
        <div className="p-2 border border-gray-400 rounded-md">
          <div className="flex justify-between m-4 ">
            <p>Price of the Service</p>
            <p>$ {data.portalPrice}</p>
          </div>
          <div className="flex justify-between m-4 ">
            <p>Priority Order Fee</p>
            <p>$ {priorityFee}</p>
          </div>
          <div className="flex justify-between m-4 border-t border-gray-300 pt-4 ">
            <p>Total</p>
            <p>$ {Number(priorityFee) + Number(data.portalPrice)}</p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Order Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              handlePaymentSuccess();
              setIsStepTwoChecked(true);
              setActiveTab("Confirmation");
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};
export const Confirmation = ({ setActiveTab }) => {
  const navigate = useNavigate();
  return (
    <div className="flex-1 flex flex-col h-full">
      <div className="h-full flex-1 flex flex-col justify-center items-center gap-4">
        <img src={completeIcon} alt="" />
        <p className="text-xl font-medium">Payment Successful!</p>
        <p className="text-[17px] font-[#555] ">Order under process.</p>
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              navigate("/orders");
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export const OrderCommunications = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readCommOrders] = useReadCommOrdersMutation();
  const [commData, setCommData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [concern, setConcern] = useState("");
  const [concernDescription, setConcernDescription] = useState("");
  const [isConcernSubmitted, setIsConcernSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  console.log(commData, "commData");

  const isFieldRequired = (fieldName) => {
    const requiredFields = [];
    return requiredFields.includes(fieldName);
  };

  const getReadCommData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await readCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 2,
    });
    if (resp.data.type === 1) {
      console.log(resp.data, "setCommData");
      setCommData(resp.data.data);
    }
  };

  const submitConcern = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await saveCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 1,
      commStatus: 2,
      readStatus: 0,
      imageurl: imageUrl,
      //parentObjId: null,
      communicationTile: concern,
      communicationText: concernDescription,
    });
    if (resp?.data?.type === 1) {
      GetToast("Conren Saved");
      setModalIsOpen(false);
      getReadCommData();
    }

    // const data = {
    //   concern: concern,
    //   concernDescription: concernDescription,
    // };
    // console.log(data);
  };

  const validateConcern = (value) => {
    return value?.trim().length > 0;
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "concern") setConcern(value);
    if (name === "concernDescription") setConcernDescription(value);
  };

  useEffect(() => {
    getReadCommData();
  }, []);

  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-[#333333] text-2xl font-medium">Concern</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          Add New Concern
        </button>
      </div>
      {commData?.map((item) => (
        <ConcernCard item={item} getReadCommData={getReadCommData} orderObjId={data?.orderObjId} />
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setModalIsOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">Add New Concern</p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <AttachUpload setImageUrl={setImageUrl} imageUrl={imageUrl} />
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

function OrderDetailsDentist({ userRole, userInfo }) {
  // debugger
  const location = useLocation();
  console.log(location.state.orderObjId, location.state.statusCode);
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderTab data={location.state} userRole={userRole} userInfo={userInfo} />
    </div>
  );
}
export default OrderDetailsDentist;

export const OrderTab = ({ data, userRole, userInfo }) => {
  // debugger
  const { user, isAuthenticated } = useAuth0();
  const [statusCode, setStatusCode] = useState(data.statusCode || 0);
  const tabNamesArray = [];
  if (
    statusCode === "104" ||
    statusCode === "106" ||
    statusCode === "110" ||
    statusCode === "119" ||
    statusCode === "122" ||
    statusCode === "125"
  ) {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Order Communications");
  } else if (statusCode === "113") {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Payment Method");
    tabNamesArray.push("Confirmation");
  } else {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Order Communications");
  }
  // const tabNamesArray = ((statusCode === "104") ? ["Order Details"] : ["Order Details", "Payment Method", "Confirmation"]);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [updateOrderById] = useUpdateOrderByIdMutation();
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);
  const [isOrderRejected, setIsOrderRejected] = useState(false);
  const [isStepOneChecked, setIsStepOneChecked] = useState(false);
  const [isStepTwoChecked, setIsStepTwoChecked] = useState(false);
  const [isStepThreeChecked, setIsStepThreeChecked] = useState(false);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };
  var outputObject = {};
  if (
    statusCode === "104" ||
    statusCode === "106" ||
    statusCode === "110" ||
    statusCode === "119" ||
    statusCode === "122" ||
    statusCode === "125"
  ) {
    outputObject = {
      "Order Details": (
        <OrderDetailSummary
          data={data}
          setIsStepOneChecked={setIsStepOneChecked}
          isStepOneChecked={isStepOneChecked}
          setActiveTab={setActiveTab}
        />
      ),
      "Order Communications": <OrderCommunications data={data} setActiveTab={setActiveTab} />,
    };
  } else if (statusCode === "113") {
    outputObject = {
      "Order Details": (
        <OrderDetailSummary
          data={data}
          setIsStepOneChecked={setIsStepOneChecked}
          isStepOneChecked={isStepOneChecked}
          setActiveTab={setActiveTab}
        />
      ),
      "Payment Method": (
        <PaymentMethod
          data={data}
          setIsStepTwoChecked={setIsStepTwoChecked}
          isStepTwoChecked={isStepTwoChecked}
          setActiveTab={setActiveTab}
        />
      ),
      Confirmation: <Confirmation setIsStepThreeChecked={setIsStepThreeChecked} />,
    };
  }

  const handleRejectOrder = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await updateOrderById({
      accessToken,
      orderId: data?.orderObjId,
      currentStatus: "6",
    });
    if (resp) {
      GetToast("Order rejected, you can contact Administrator for any queries.");
    }
  };

  const handleDeliverySubmit = async (type) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (type === "A") {
      const resp = await updateOrderById({
        accessToken,
        orderId: data?.orderObjId,
        currentStatus: "8",
      });
      if (resp) {
        GetToast("Order Delivered, you can contact Administrator for any queries.");
      }
    } else {
      const resp = await updateOrderById({
        accessToken,
        orderId: data?.orderObjId,
        currentStatus: "9",
      });
      if (resp) {
        GetToast("Order Dispute Raised, you can contact Administrator for any queries.");
      }
    }
  };

  console.log(data.status, data.statusCode, "Status");
  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center justify-between w-full">
          <p
            className="font-medium text-[34px] text-white
"
          >
            {data.serviceName}
          </p>
          {activeTab !== "Order Details" || statusCode !== "113" ? null : (
            <div className="flex gap-2 items-center">
              <button
                onClick={() => setRejectModalOpen(true)}
                className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px]"
              >
                Reject
              </button>
            </div>
          )}
          {data?.statusCode === "119" ? (
            <p className="px-4 py-2 text-[#F35F5D] rounded-lg font-medium text-[17px]">
              {data.status}
            </p>
          ) : data?.statusCode === "122" ? (
            <div className="flex gap-2 items-center">
              <button
                onClick={() => handleDeliverySubmit("A")}
                className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px]"
              >
                Delivered
              </button>
              <button
                onClick={() => handleDeliverySubmit("R")}
                className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px]"
              >
                Raise Dispute
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="bg-secondary p-4 rounded-md flex-1 flex flex-col">
        {statusCode !== "113" ? null : (
          <p
            className="text-xl font-medium text-[#555]
 my-4"
          >
            Price: <span className="text-[#4E81F4] font-bold">$ {data.portalPrice}</span>{" "}
          </p>
        )}
        {statusCode !== "113" ? null : (
          <StageBox
            titleOne={"Order Details"}
            isStepOneChecked={isStepOneChecked}
            titleTwo={"Payment"}
            isStepTwoChecked={isStepTwoChecked}
            titleThree={"Confirmation"}
            isStepThreeChecked={isStepThreeChecked}
          />
        )}

        <div className="Tabs flex-1 h-full">
          {/* Tab nav */}
          <ul className="NAV flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-[#222026] border-opacity-10 py-4">
            {tabNamesArray.map((title, i) => {
              return (
                <li key={i + 1} className="relative" onClick={() => handleTabSuper(title)}>
                  <div
                    className={
                      activeTab === title
                        ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-[#4381f4] before:left-0 before:-bottom-4"
                        : "hover:text-primary cursor-pointer text-[#999] font-normal"
                    }
                  >
                    {title}
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="outlet md:h-fit h-[50vh] overflow-auto pt-4 ">
            {Object.keys(outputObject).map((key) => {
              if (tabNamesArray.includes(key)) {
                return <>{activeTab === key ? <>{outputObject[key]}</> : null}</>;
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={acceptModalOpen}
        onRequestClose={() => setAcceptModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setAcceptModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">Accept Order</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isOrderAccepted ? (
                  <>
                    <img src={assignSuccess} alt="" />
                    <p className="text-2xl font-semibold">Congratulations!</p>
                    <p className="text-center my-0 md:px-12">
                      Your order is successfully accepted.
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={assignedIcon} alt="" />
                    <p className="text-center my-4 md:px-12">
                      By clicking confirm this order will be accepted for
                      <span className="text-xl text-[#4E81F4]"> 29$</span>
                    </p>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isOrderAccepted ? null : (
                  <button
                    onClick={() => setAcceptModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    setIsOrderAccepted(true);
                    if (isOrderAccepted) {
                      setAcceptModalOpen(false);
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isOrderAccepted ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        isOpen={rejectModalOpen}
        onRequestClose={() => setRejectModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setRejectModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">Reject Order</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isOrderRejected ? (
                  <>
                    <img src={rejectedIcon} alt="" />

                    <p className="text-center my-0 md:px-12">
                      We will review your rejection will get back to you with a best quote.
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={rejectedIcon} alt="" />
                    <p className="text-center my-4 md:px-12">
                      Are you sure you want to reject this order!
                    </p>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isOrderRejected ? null : (
                  <button
                    onClick={() => setRejectModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    setIsOrderRejected(true);
                    if (isOrderRejected) {
                      handleRejectOrder();
                      setRejectModalOpen(false);
                      navigate("/orders");
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isOrderRejected ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
