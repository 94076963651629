/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MapInputField from "../InputField-Comps/MapInputField";
import LocationInput from "../InputField-Comps/LocationInput";
import TextInputField from "../InputField-Comps/TextInputField";
import NumberInputField from "../InputField-Comps/NumberInputField";
import {
  useUpdateAddressDetailsMutation,
  useSaveAddressDetailsMutation,
} from "../../services/user";
import UseAccessToken from "../../hooks/UseAccessToken";
import { GetToast } from "../Toast-Comps/GetToast";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import LocationInputV2 from "../InputField-Comps/LocationInputV2";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { MAP_LIBRARIES } from "../../Utils/utility";

const InputFieldsAddress = ({ setModalIsOpen, profileDetailData, setIsProfileUpdated }) => {
  const [location, setLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [country, setCountry] = useState("");
  const [labAddress, setLabAddress] = useState("");
  const [labLocation, setLabLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [labCountry, setLabCountry] = useState("");
  const [labLatitude, setLabLatitude] = useState("");
  const [labLongitude, setLabLongitude] = useState("");
  const [labPostalCode, setLabPostalCode] = useState("");
  const [ownPhysicalLaboratory, setOwnPhysicalLaboratory] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isAddressFormSubmitted, setIsAddressFormSubmitted] = useState(false);
  const accessToken = UseAccessToken();

  console.log('ownPhysicalLaboratory', ownPhysicalLaboratory)

  //Google Maps comps and state here
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const [updateAddressDetails] = useUpdateAddressDetailsMutation();
  const [saveAddressDetails] = useSaveAddressDetailsMutation();

  useEffect(() => {
    setAddress(profileDetailData?.userAddressDetails?.[0]?.address);
    setCountry(profileDetailData?.userAddressDetails?.[0]?.country);
    setPincode(profileDetailData?.userAddressDetails?.[0]?.pincode);
    setLatitude(profileDetailData?.userAddressDetails?.[0]?.latitude);
    setLongitude(profileDetailData?.userAddressDetails?.[0]?.longitude);
    setLocation({
      lat: profileDetailData?.userAddressDetails?.[0]?.latitude,
      lng: profileDetailData?.userAddressDetails?.[0]?.longitude,
    });
    setLabAddress(profileDetailData?.userAddressDetails?.[0]?.labAddress);
    setLabCountry(profileDetailData?.userAddressDetails?.[0]?.labCountry);
    setLabPostalCode(profileDetailData?.userAddressDetails?.[0]?.labPostalCode);
    setLabLatitude(profileDetailData?.userAddressDetails?.[0]?.labLatitude);
    setLabLongitude(profileDetailData?.userAddressDetails?.[0]?.labLongitude);
    setLabLocation({
      lat: profileDetailData?.userAddressDetails?.[0]?.labLatitude,
      lng: profileDetailData?.userAddressDetails?.[0]?.labLongitude,
    });
    setOwnPhysicalLaboratory(
      profileDetailData?.userAddressDetails?.[0]?.ownPhysicalLaboratory ? "Yes" : "No"
    );
  }, [profileDetailData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "address") setAddress(value);
    if (name === "country") setCountry(value);
    if (name === "pincode") setPincode(value);
    if (name === "ownPhysicalLaboratory") {
      setOwnPhysicalLaboratory(value);
      setLabAddress("");
      setLabCountry("");
      setLabLocation("");
      setLabLatitude("");
      setLabLongitude("");
      setLabPostalCode("");
    }
    if (name === "labAddress") setLabAddress(value);
    if (name === "labCountry") setLabCountry(value);
    if (name === "labPostalCode") setLabPostalCode(value);
  };
  const handleSubmit = async (event) => {
    // debugger
    event.preventDefault();

    // Perform validation for each input
    const isAddressValid = !isFieldRequired("address") || validateAddress(address);
    const isCountryValid = !isFieldRequired("country") || validateCountry(country);
    const isPinCodeValid = !isFieldRequired("pincode") || validatePinCode(pincode);

    // Set overall form validity
    setIsValid(isAddressValid && isCountryValid && isPinCodeValid);
    setIsAddressFormSubmitted(true);

    if (isAddressValid && isCountryValid && isPinCodeValid) {
      // Submit the form or perform any other action
      const data = {
        country: country,
        pincode: pincode,
        address: address,
        coordinates: location,
        latitude: latitude,
        longitude: longitude,
        ownPhysicalLaboratory: ownPhysicalLaboratory,
        labAddress: labAddress,
        labCountry: labCountry,
        labPostalCode: labPostalCode,
        labLatitude: labLatitude ?? "0",
        labLongitude: labLongitude ?? "0",
      };

      if (profileDetailData?.userAddressDetails?.length !== 0) {
        const resp = await updateAddressDetails({
          accessToken,
          ...data,
          _id: profileDetailData?.userAddressDetails?.[0]?._id,
        }).unwrap();
        if (resp?.type === 1) {
          GetToast("Address Updated Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }

        setIsProfileUpdated(true);
        setModalIsOpen(false);
      } else {
        const resp = await saveAddressDetails({
          accessToken,
          ...data,
        }).unwrap();
        if (resp?.type === 1) {
          GetToast("Address Saved Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }

        setIsProfileUpdated(true);
        setModalIsOpen(false);
      }
    } else {
      console.log("Form Not Valid");
    }
  };

  const validateAddress = (value) => {
    return value?.trim().length > 0;
  };
  const validateCountry = (value) => {
    return value?.trim().length > 0;
  };

  const validatePinCode = (value) => {
    // const phoneRegex = /^\d/;
    // return phoneRegex.test(value);
    return value?.trim().length > 0;
  };
  const validateConsent = (value) => {
    return value?.trim().length > 0;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["country", "address", "pincode"];
    return requiredFields.includes(fieldName);
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          {/* <LocationInput
            labelText={"Address"}
            labelColor={"#777"}
            value={address}
            setValue={setAddress}
            errorMessage={"Please enter a address"}
            placeholder={"Enter Address"}
            htmlFor={"address"}
            name={"address"}
            handleInputChange={handleInputChange}
            validationFunctionName={validateAddress}
            isSubmitted={isAddressFormSubmitted}
            isFieldRequired={isFieldRequired("address")}
            setLocation={setLocation}
            setCountry={setCountry}
            setPinCode={setPincode}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
          <div className="relative">
            <MapInputField location={location} setLocation={setLocation} />
          </div> */}
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <div className=" col-span-2">
                <LocationInputV2
                  setValue={setAddress}
                  placeholder={"Search a Addresss location"}
                  name={"addressSearch"}
                  setCountry={setCountry}
                  setLatitude={setLatitude}
                  setLocation={setLocation}
                  setLongitude={setLongitude}
                  setPinCode={setPincode}
                />
              </div>
              <div className="col-span-2 row-span-2 mb-2">
                <GoogleMap
                  mapContainerClassName="h-[140px]"
                  center={location}
                  zoom={10}
                  onClick={(e) => {
                    setLatitude(e.latLng.lat());
                    setLongitude(e.latLng.lng());
                    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                  clickableIcons={true}
                >
                  <Marker position={location} />
                </GoogleMap>
              </div>
            </>
          )}

          <div className="col-span-2 row-span-2">
            <TextAreaInputField
              labelColor={"#777"}
              labelText={"Address "}
              errorMessage={"Enter Valid Address"}
              placeholder={"Write here"}
              isFieldRequired={isFieldRequired("address")}
              htmlFor={"address"}
              name={"address"}
              value={address}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAddress}
              isSubmitted={isAddressFormSubmitted}
              rows={4}
              cols={5}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Country"}
              errorMessage={"Please enter a country name"}
              placeholder={"Country name"}
              htmlFor={"country"}
              name={"country"}
              value={country}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCountry}
              isSubmitted={isAddressFormSubmitted}
              isFieldRequired={isFieldRequired("country")}
            />
          </div>
          <div className="relative">
            <NumberInputField
              labelColor={"#777"}
              labelText={"Pin Code"}
              errorMessage={"Please enter a valid pin code"}
              placeholder={"Pin Code"}
              htmlFor={"pincode"}
              name={"pincode"}
              value={pincode}
              handleInputChange={handleInputChange}
              validationFunctionName={validatePinCode}
              isSubmitted={isAddressFormSubmitted}
              isFieldRequired={isFieldRequired("pincode")}
            />
          </div>
          <div className="relative mb">
            <label className="text-[16px] text-white" htmlFor="ownPhysicalLaboratory">
              If you own or operate or work with a physical dental laboratory that wishes to produce
              and locally ship physically produced dental devices or appliances, please give us your
              details below :
              {isFieldRequired("ownPhysicalLaboratory") ? (
                <span className="text-red-500 ml-1">*</span>
              ) : (
                ""
              )}
            </label>
            <div className="flex-1 mt-4 mb-4 flex md:flex-row flex-col md:gap-12 gap-4">
              <div className="flex">
                <CircularCheckbox
                  value="Yes"
                  name="ownPhysicalLaboratory"
                  checked={ownPhysicalLaboratory === "Yes"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">Yes</p>
              </div>
              <div className="flex">
                <CircularCheckbox
                  value="No"
                  name="ownPhysicalLaboratory"
                  checked={ownPhysicalLaboratory === "No"}
                  onChange={handleInputChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-[16px] text-white">No</p>
              </div>
            </div>
            {isFieldRequired("ownPhysicalLaboratory") &&
              validateConsent(ownPhysicalLaboratory) === false &&
              isAddressFormSubmitted && (
                <p className="text-red-500 text-[15px]">This field is required</p>
              )}
          </div>
        </div>
        <div className={`flex flex-col gap-0 ${ownPhysicalLaboratory === "Yes" ? "" : "hidden"}`}>
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <div className=" col-span-2">
                <LocationInputV2
                  setValue={setLabAddress}
                  placeholder={"Search an address location"}
                  name={"addressSearch"}
                  setCountry={setLabCountry}
                  setLatitude={setLabLatitude}
                  setLocation={setLabLocation}
                  setLongitude={setLabLongitude}
                  setPinCode={setLabPostalCode}
                />
              </div>
              <div className="col-span-2 row-span-2 mb-2">
                <GoogleMap
                  mapContainerClassName="h-[140px]"
                  center={labLocation}
                  zoom={10}
                  onClick={(e) => {
                    setLabLatitude(e.latLng.lat());
                    setLabLongitude(e.latLng.lng());
                    setLabLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                  clickableIcons={true}
                >
                  <Marker position={labLocation} />
                </GoogleMap>
              </div>
            </>
          )}

          <div className="col-span-2 row-span-2">
            <TextAreaInputField
              labelColor={"#777"}
              labelText={"Laboratory Address "}
              errorMessage={"Enter Valid Address"}
              placeholder={"Write here"}
              isFieldRequired={isFieldRequired("labAddress")}
              htmlFor={"labAddress"}
              name={"labAddress"}
              value={labAddress}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAddress}
              isSubmitted={isAddressFormSubmitted}
              rows={4}
              cols={5}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Country "}
              errorMessage={"Please enter a country name"}
              placeholder={"Country name"}
              htmlFor={"labCountry"}
              name={"labCountry"}
              value={labCountry}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCountry}
              isSubmitted={isAddressFormSubmitted}
              isFieldRequired={isFieldRequired("labCountry")}
            />
          </div>
          <div className="relative">
            <NumberInputField
              labelColor={"#777"}
              labelText={"Postal code (Zip code/Pin code)"}
              errorMessage={"Please Enter Valid Pin Code"}
              placeholder={"Please Enter your Pin Code"}
              isFieldRequired={isFieldRequired("labPostalCode")}
              htmlFor={"labPostalCode"}
              name={"labPostalCode"}
              value={labPostalCode}
              handleInputChange={handleInputChange}
              validationFunctionName={validatePinCode}
              isSubmitted={isAddressFormSubmitted}
            />
          </div>
        </div>

        <div className="flex pb-4 items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsAddress;
