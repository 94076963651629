import { useRef, useState } from "react";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import { useNavigate } from "react-router-dom";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import imagePlaceholderIcon from "../../Assets/images/imagePlaceholderIcon.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Modal from "react-modal";

import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import { CORRECTION_TECHNIQUE, ORDER_STAGE } from "../../constants/orderForms";
import { isImageFormatDisplayable } from "../../Utils/utility";

function OrthodonticOrderConfirmation({
  orderFor,
  orderObjId,
  userInfo,
  userRole,
  setActiveTab,
  setIsDisclaimerAccepted,
  selectedServiceCategory,
  isOrderPlaced = false,
  placeOrderHandler,
}) {
  return (
    <div className="flex w-full">
      <OrderConfirmationTab
        orderObjId={orderObjId}
        userRole={userRole}
        userInfo={userInfo}
        setActiveTab={setActiveTab}
        setIsDisclaimerAccepted={setIsDisclaimerAccepted}
        selectedServiceCategory={selectedServiceCategory}
        isOrderPlaced={isOrderPlaced}
        placeOrderHandler={placeOrderHandler}
      />
    </div>
  );
}

export default OrthodonticOrderConfirmation;

export const OrderConfirmationTab = ({
  userInfo,
  userRole,
  orderObjId,
  setActiveTab,
  setIsDisclaimerAccepted,
  selectedServiceCategory,
  isOrderPlaced,
  placeOrderHandler,
}) => {
  const pdfRef = useRef();
  const navigate = useNavigate();

  const { orthodonticOrderDetails } = useSelector((state) => state.orderFormDetails);
  const { user, isAuthenticated } = useAuth0();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width * 0.264583; // Convert pixels to mm (1 px = 0.264583 mm)
      const pdfHeight = canvas.height * 0.264583; // Convert pixels to mm

      const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]); // Create a PDF with custom page size
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Add the image to the PDF
      pdf.save("download.pdf");
    });
  };

  return (
    <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full bg-secondary px-4 rounded-t-xl py-4">
        <p className="font-medium text-[34px] text-white">Orthodontic Planning Order</p>
        {!isOrderPlaced && (
          <>
            <div onClick={downloadPDF} className="flex gap-4 items-center">
              <img src={printerIcon} alt="" />
              <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
                <img src={exitIcon} alt="" />
                Export
              </button>
            </div>
            <div className="flex items-center md:justify-end gap-4 flex-wrap">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setActiveTab(ORDER_STAGE.DISCLAIMER);
                  setIsDisclaimerAccepted(false);
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Back
              </button>
              <button
                onClick={() => {
                  placeOrderHandler();
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
              >
                Get Quote
              </button>
            </div>
          </>
        )}
      </div>

      <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full bg-secondary rounded-b-xl px-4 w-full">
        <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md">
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className=" text-white md:block hidden ">Service Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
              <AiOutlineCheck className=" " />
            </div>
            <p className="text-white md:block hidden ">Order Details</p>
          </div>
          <div className="md:w-[200px] h-[20px] ">
            <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
          </div>
          <div className="flex items-center gap-4">
            {isOrderPlaced ? (
              <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
            ) : (
              <div className="bg-orange-500 h-8 w-8 rounded-full border text-[#fff] font-medium flex items-center justify-center">
                3
              </div>
            )}

            <p className="text-white font-semibold md:block hidden ">Confirmation</p>
          </div>
        </div>
        {isOrderPlaced ? (
          <div className="flex-1 flex flex-col justify-between h-full">
            <div className="h-full w-full flex-1 flex flex-col justify-center items-center gap-2">
              <img src={orderPlacedIcon} alt="" />
              <p className="text-2xl text-white font-semibold">Order Placed Successfully</p>
              <p className="text-[17px] text-white">
                Order has been sent for review will get back to you with the best possible quote in
                48 hours
              </p>
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full bg-secondary p-3" ref={pdfRef}>
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient Initials</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.patientInitials || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient details have been anonymized</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.detailsAnonymized ? "Yes" : "No"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Date of Birth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.dob || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Gender</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.gender || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Shipping Address</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.address || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Country</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.country || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Postal code (Zip code/Pin code)</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.patientDetails?.pinCode || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">The patient has any missing teeth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.caseDetails?.hasMissingTeeth || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Missing Teeths</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orthodonticOrderDetails?.caseDetails?.missingTeethSelectedTeeth?.join(", ") ||
                      "NA"}
                  </p>
                </div>

                {orthodonticOrderDetails?.caseDetails?.services?.length > 0 && (
                  <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                    <p className="text-xl text-white text-center uppercase">Services Selected</p>
                  </div>
                )}
              </div>
              {orthodonticOrderDetails?.caseDetails?.services?.map((service) => {
                const serviceDetails = selectedServiceCategory?.catservices?.find(
                  (d) => d?.serviceObjId === service?.serviceObjId
                );
                console.log("serviceDetails", serviceDetails);
                if (serviceDetails?.serviceObjId) {
                  return (
                    <div
                      key={"serviceItem-" + serviceDetails?.serviceObjId}
                      className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
                    >
                      <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                        <p className="text-[15px] text-white font-semibold">Service Name</p>
                        <p className="text-primary font-medium mt-2 uppercase">
                          {serviceDetails?.serviceName}
                        </p>
                      </div>
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <>
                          <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                            <p className="text-[15px] text-white">
                              Are there any teeth that should not be moved?
                            </p>
                            <p className="text-[#555] font-medium mt-2">
                              {service?.teethNotToMoveOption || "NA"}
                            </p>
                          </div>
                          {service?.teethNotToMoveOption === "Yes" && (
                            <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                              <p className="text-[15px] text-white">
                                Teeth that should not be moved
                              </p>
                              <p className="text-[#555] font-medium mt-2">
                                {service?.teethNotToMove?.join(", ") || "NA"}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {["Clear Retainer"].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            Would you like a printable retainer or a model that you will use for
                            thermoforming?
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.printableAligners || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Options</p>
                          <p className="text-[#555] font-medium mt-2">{service?.options || "NA"}</p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">
                            If you have chosen STL files, would you like printable aligners or
                            models that you will use for thermoforming?
                          </p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.printableAligners || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Clinical Conditions</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.clinicalConditions?.join(", ") || "NA"}
                          </p>
                        </div>
                      )}
                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Arches to treat</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.archesToTreat || "NA"}
                        </p>
                      </div>
                      {["Clear Aligners full arch", "Clear Aligners full arch refinement"].includes(
                        serviceDetails?.serviceName
                      ) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Molar Occlusal Goals</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.molarOcclusalGoals || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Midline</p>
                          <p className="text-[#555] font-medium mt-2">{service?.midline || "NA"}</p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white">Inter Proximal Reduction (IPR)</p>
                          <p className="text-[#555] font-medium mt-2">{service?.ipr || "NA"}</p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <>
                          <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                            <p className="text-[15px] text-white">Overjet Correction</p>
                            <p className="text-[#555] font-medium mt-2">
                              {service?.overjetCorrectionOption || "NA"}
                            </p>
                          </div>
                          {service?.overjetCorrectionOption === CORRECTION_TECHNIQUE.IMPROVE && (
                            <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                              <p className="text-[15px] text-white">
                                Overjet Correction Improve length
                              </p>
                              <p className="text-[#555] font-medium mt-2">
                                {service?.overjetCorrectionOptionImproveLength || "NA"}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <>
                          <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                            <p className="text-[15px] text-white">Overbite Correction</p>
                            <p className="text-[#555] font-medium mt-2">
                              {service?.overbiteCorrectionOption || "NA"}
                            </p>
                          </div>
                          {service?.overbiteCorrectionOption === CORRECTION_TECHNIQUE.IMPROVE && (
                            <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                              <p className="text-[15px] text-white">
                                Overbite Correction Improve length
                              </p>
                              <p className="text-[#555] font-medium mt-2">
                                {service?.overbiteCorrectionOptionImproveLength || "NA"}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white"> Crossbite Correction</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.crossbiteCorrection || "NA"}
                          </p>
                        </div>
                      )}
                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <>
                          <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                            <p className="text-[15px] text-white"> Attachments allowed?</p>
                            <p className="text-[#555] font-medium mt-2">
                              {service?.attachmentsAllowed || "NA"}
                            </p>
                          </div>
                          {service?.attachmentsAllowed === "Yes" && (
                            <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                              <p className="text-[15px] text-white"> Attachments allowed on?</p>
                              <p className="text-[#555] font-medium mt-2">
                                {service?.attachmentsAllowedOn || "NA"}
                              </p>
                            </div>
                          )}
                        </>
                      )}

                      {[
                        "Clear Aligners anterior",
                        "Clear Aligners full arch",
                        "Clear Aligners anterior refinement",
                        "Clear Aligners full arch refinement",
                      ].includes(serviceDetails?.serviceName) && (
                        <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                          <p className="text-[15px] text-white"> Retainer Required?</p>
                          <p className="text-[#555] font-medium mt-2">
                            {service?.retainerRequired || "NA"}
                          </p>
                        </div>
                      )}

                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Preferred design software?</p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.preferredSoftware || "NA"}
                        </p>
                      </div>

                      <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                        <p className="text-[15px] text-white">Additional Instructions </p>
                        <p className="text-[#555] font-medium mt-2">
                          {service?.additionalInstructions || "NA"}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE md:col-span-3 pb-4 border-b uppercase border-1 border-dotted border-white border-opacity-20">
                  <p className="text-xl text-white text-center">Uploads</p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Photographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.photographs)?.map(function (
                      key,
                      index
                    ) {
                      if (orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url) {
                        return (
                          <div
                            key={"photographItem" + key}
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(
                                orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                              )
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="w-7 aspect-square object-cover"
                              src={
                                isImageFormatDisplayable(
                                  orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                                )
                                  ? orthodonticOrderDetails?.uploadFiles?.photographs[key]?.s3Url
                                  : imagePlaceholderIcon
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    })}
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.photographs || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Radiographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.radiographs)?.map(function (
                      key,
                      index
                    ) {
                      if (orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url) {
                        return (
                          <div
                            key={"radiographItem" + key}
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(
                                orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                              )
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="w-7 aspect-square object-cover"
                              src={
                                isImageFormatDisplayable(
                                  orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                                )
                                  ? orthodonticOrderDetails?.uploadFiles?.radiographs[key]?.s3Url
                                  : imagePlaceholderIcon
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    })}
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.radiographs || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Intra Oral Scans</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.intraOralScans)?.map(
                      function (key, index) {
                        if (orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url) {
                          return (
                            <div
                              key={"intraOralScansItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]?.s3Url
                                  )
                                    ? orthodonticOrderDetails?.uploadFiles?.intraOralScans[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.intraOralScans || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Raw DICOM Data</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.rawDicomData)?.map(
                      (key, index) => {
                        if (orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                                  )
                                    ? orthodonticOrderDetails?.uploadFiles?.rawDicomData[key]?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.rawDicomData || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Additional Files</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.additionalFiles)?.map(
                      (key, index) => {
                        if (orthodonticOrderDetails?.uploadFiles?.additionalFiles?.[key]?.s3Url) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  orthodonticOrderDetails?.uploadFiles?.additionalFiles?.[key]
                                    ?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    orthodonticOrderDetails?.uploadFiles?.additionalFiles[key]
                                      ?.s3Url
                                  )
                                    ? orthodonticOrderDetails?.uploadFiles?.additionalFiles[key]
                                        ?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {Object.keys(orthodonticOrderDetails?.uploadFiles?.additionalFiles || {})
                      ?.length === 0 && "NA"}
                  </div>
                </div>
                {isImageModalOpen && (
                  <div className="image-modal blur-md">
                    <Modal
                      isOpen={isImageModalOpen}
                      onRequestClose={() => setIsImageModalOpen(false)}
                      contentLabel="Custom Modal"
                      className="image-modal !bg-black/80"
                      // style={customStyles}
                      ariaHideApp={false}
                    >
                      <div
                        className="MODAL-BODY flex flex-col h-full"
                        onClick={() => closeImageModal()}
                      >
                        <button className="w-full text-right">
                          <AiOutlineClose className="ml-auto text-white" />
                        </button>
                        <img
                          className={`${
                            isImageFormatDisplayable(selectedImage) ? "h-[90%]" : "h-[85%]"
                          } w-full object-contain`}
                          src={
                            isImageFormatDisplayable(selectedImage)
                              ? selectedImage
                              : imagePlaceholderIcon
                          }
                          onClick={(e) => e.stopPropagation()}
                          alt=""
                        />
                        {!isImageFormatDisplayable(selectedImage) && (
                          <p className="mt-3 font-medium text-white text-lg text-center">
                            File Preview not available
                          </p>
                        )}
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
