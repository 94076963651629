import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const uploadFileApi = createApi({
  reducerPath: "uploadFileApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    saveFile: builder.mutation({
      query: ({ accessToken, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "/uploadAsset",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteFile: builder.mutation({
      query: ({ accessToken, _id }) => {
        return {
          url: "/asset/delete",
          method: "POST",
          body: { _id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useSaveFileMutation, useDeleteFileMutation } = uploadFileApi;
