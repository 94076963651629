import React, { useEffect, useState } from "react";

// import { useDispatch } from "react-redux";
// import { readData } from "../../actions/tableService";

import CardOne from "../../Components/Dashboard-Comps/CardOne";
import iconOne from "../../Assets/images/frame.svg";
import fileIcon from "../../Assets/images/fileCardIcon.svg";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import QuickOrderCard from "../../Components/Dashboard-Comps/QuickOrderCard";
import toothPic from "../../Assets/images/toothPic.svg";
import { IoIosArrowForward } from "react-icons/io";
// import CustomTabs from "../../Components/Dashboard-Comps/CustomTabs-Dep";
import bell from "../../Assets/images/bellIcon.svg";
import greenTick from "../../Assets/images/greenTick.svg";
import graph from "../../Assets/images/graph.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import logo from "../../Assets/images/LOGO.svg";
import { Tooltip } from "react-tooltip";
import OrderHistoryCard from "../../Components/Dashboard-Comps/OrderHistoryCard";

// import CustomTabsNew from "../../Components/Dashboard-Comps/";
import CustomTabsDashboard from "../../Components/Dashboard-Comps/CustomTabsDashboard";
import { LoginButton } from "../Login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import {
  iconArray,
  superAdminIconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
} from "../../constants/NavbarRoutesArray";
import { useGetProfileMutation } from "../../services/user";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllServiceDetails,
  setUserServiceDetails,
} from "../../reducers/SPServiceDetailsReducer";
import { useGetUserServiceMutation } from "../../services/userServices";
import { useGetServicesForCCMutation } from "../../services/services";
import { useGetOrdersForServiceProviderMutation } from "../../services/orders";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { useReadPersonalDetailsMutation } from "../../services/user";
import Notification from "../../Components/Notification/Notification";

function DashboardSP({ userRoles, userInfo, setUserInfo }) {
  const dispatch = useDispatch();
  const [getOrdersForServiceProvider] = useGetOrdersForServiceProviderMutation();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();
  const [getUserService, { isLoading: getUserServiceIsLoading }] = useGetUserServiceMutation();
  const [getServicesForCC] = useGetServicesForCCMutation();
  const { allService, userService } = useSelector((state) => state.servicesDetails);
  const [getProfile] = useGetProfileMutation();
  const [servicesData, setServicesData] = useState(allService);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userRole, setUserRole] = React.useState(userRoles);
  const [userServicesData, setUserServicesData] = useState(userService);
  const [isServicesUpdated, setIsServicesUpdated] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  // const getPersonalDetails = async (accessToken) => {
  //   return await readPersonalDetails(accessToken).unwrap();
  // };

  // const readProfileInfo = async () => {
  //   const accessToken = await getAccessTokenSilently({
  //     authorizationParams: {
  //       audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
  //     },
  //   });
  //   const response = await readPersonalDetails({ accessToken }).unwrap(
  //     (res) => res
  //   );
  //   dispatch(setUserProfileDetails(response?.data));
  //   setProfileDetailData(response?.data);
  //   setProfileId(response?.data?.[0]?.["_id"]);
  // };

  // eslint-disable-next-line no-unused-vars
  const roles = user?.["https://localhost:3000/roles"] || [];

  const getOrdersData = async (accessToken) => {
    const resp = await getOrdersForServiceProvider(accessToken).unwrap();
    if (resp) {
      if (resp.data.length === 0) {
        // GetToast('Currently there are no orders in due', "success");
      }
      setOrdersData(resp.data);
    }
  };

  const readOrders = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getOrdersData(accessToken);
  };

  // for quick order section
  const arrayOfCards = [
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
    <QuickOrderCard
      image={toothPic}
      bgColor={"#fff"}
      title={"Orthodontic Service"}
      textColor={"#555"}
    />,
  ];

  // for order history section
  const arrayOfTilesOne = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];
  const arrayOfTilesTwo = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];

  useEffect(() => {
    if (userInfo[0]?.crowdHireId === undefined) {
      const getUpdatedProfile = async () => {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
          },
        });
        const response = await getProfile(accessToken).unwrap();
        if (response) {
          localStorage.setItem("userCrowdHireId", response?.chDetails[0]?.crowdHireId);
          localStorage.setItem("useractive", response?.chDetails[0]?.active);
          setUserInfo(response?.chDetails);
        }
      };
      getUpdatedProfile();
    }
    readOrders();
  }, []);

  const getServicesData = async (accessToken) => {
    const response = await getServicesForCC(accessToken).unwrap();
    dispatch(setAllServiceDetails(response?.data));
    setServicesData(response?.data);
  };

  const getUserServicesData = async (accessToken) => {
    const response = await getUserService({ accessToken }).unwrap();
    dispatch(setUserServiceDetails(response?.data));
    setUserServicesData(response?.data);
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesData(accessToken);
    await getUserServicesData(accessToken);
  };

  const prepareServicesElements = () => {
    if (userServicesData?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      userServicesData.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            id={eachService._id}
            active={eachService.active !== undefined ? eachService.active : 0}
            image={eachService.serviceObjId.image}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService.serviceObjId.serviceName}
            serviceDescription={eachService.serviceObjId.serviceDescription}
            serviceSpecilization={eachService.specialisation}
            price={eachService.actualPrice ? eachService.actualPrice : ""}
            offerPrice={eachService.offerPrice ? eachService.offerPrice : ""}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  useEffect(() => {
    if (isServicesUpdated === true) {
      readServicesInfo();
    }
    setIsServicesUpdated(false);
  }, [isServicesUpdated]);

  useEffect(() => {
    if (!userServicesData?.length || !servicesData?.length) {
      readServicesInfo();
    }
    setIsServicesUpdated(false);
  }, []);

  return (
    <>
      <div className="flex bg-black">
        <Navbar
          iconArray={
            userRole === "89uij7"
              ? superAdminIconArray
              : userRole === "676dza2"
              ? iconArray
              : spIconArray
          }
          navHoverCss={navHoverCss}
          navActiveCss={navActiveCss}
          middleSection={false}
          middleTabTitleArray={[""]}
          middleTabContentObject={{}}
          userRole={userRole}
          active={"Dashboard"}
        />
        <div className="MAIN-DASH bg-background p-4 w-full h-full">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3 text-textP">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {userProfileDetails?.firstName ?? user?.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {userRole !== "89uij7" ? (
                userInfo?.length > 0 ? (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    {userInfo[0]?.active === 1 ? (
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                      </>
                    ) : (
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={
                          "Your registration is under review and you will be notified once admin approves your registration."
                        }
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                    )}
                  </button>
                ) : (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    Account Not Active
                    <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
                  </button>
                )
              ) : (
                <></>
              )}

              <Notification />
            </div>
          </div>
          <div className="SEARCH-BAR grid grid-cols-5 grid-rows-1 mb-8 gap-12">
            <div className="col-span-5 md:col-span-3 flex">
              <p className="font-medium text-[34px] leading-7 mr-8 text-textP">Dashboard</p>
            </div>
            <div className="md:block hidden col-span-2"></div>
          </div>
          <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-5 md:grid-rows-2 gap-y-8 md:gap-12">
            <div className="CARD-GRID col-span-3 grid lg:grid-cols-3 md:grid-cols-2, gap-4">
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={iconOne}
                bigBox={true}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders Delivered"}
                count={ordersData.filter((item) => item.statusCode === "121").length}
                circles={true}
              />
              <CardOne
                bgOne={"#0A0B0C"}
                bgTwo={"#0A0B0C"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"#555555"}
                numberTextColor={"white"}
                headingTextColor={"#555555"}
                subtextTextColor={"#555555"}
                title={"Orders In Review"}
                count={ordersData.filter((item) => item.statusCode === "106").length}
              />

              <CardOne
                bgOne={"#0A0B0C"}
                bgTwo={"#0A0B0C"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"#555555"}
                numberTextColor={"white"}
                headingTextColor={"#555555"}
                subtextTextColor={"#555555"}
                title={"Orders In Progress"}
                count={ordersData.filter((item) => item.statusCode === "115").length}
              />
              <CardOne
                bgOne={"#0A0B0C"}
                bgTwo={"#0A0B0C"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"#555555"}
                numberTextColor={"white"}
                headingTextColor={"#555555"}
                subtextTextColor={"#555555"}
                title={"Orders Rejected"}
                count={ordersData.filter((item) => item.statusCode === "108").length}
              />
              <CardOne
                bgOne={"#0A0B0C"}
                bgTwo={"#0A0B0C"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"#555555"}
                numberTextColor={"white"}
                headingTextColor={"#555555"}
                subtextTextColor={"#555555"}
                title={"Total Earnings"}
                count={ordersData.filter((item) => item.statusCode === "115").length}
              />
            </div>
            <div className="SIDE-INFO-BAR rounded-lg row-span-1 md:row-span-2 col-span-2 bg-secondary p-4">
              <div className="flex justify-between items-center mb-4">
                <p className="font-semibold text-xl text-white">My Services</p>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Find Service"
                    className="text-white placeholder:text-[#99] placeholder:text-[16px] placeholder:text-gray-300 text-[16px] pl-8 w-36 bg-none focus:outline-none bg-secondary"
                  />
                  <img className="absolute top-1 left-1" src={searchIcon} alt="" />
                </div>
              </div>
              {userServicesData.length === 0 ? (
                <div role="status" className="animate-pulse flex-col">
                  <div className="w-full flex gap-5">
                    <div className="h-24 w-1/3 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
                    <div className="h-24 w-1/3 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
                    <div className="h-24 w-1/3 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
                  </div>
                  <div className="w-full flex gap-5">
                    <div className="h-24 w-1/3 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
                    <div className="h-24 w-1/3 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
                    <div className="h-24 w-1/3 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="CAROUSEL mb-8">
                    <div className="md:block hidden">
                      <CustomCarousel
                        rows={2}
                        cols={3}
                        gapX={20}
                        gapY={20}
                        // numberOfSliders={[
                        //   // these are three slides and three arrays of cards for each slide
                        //   { 0: prepareServicesElements() },
                        // ]}
                        // CHANGE DATA HERE
                        completeSliders={prepareServicesElements()}
                        numberOfCardsShownInSingleSlide={5}
                        //handleCardOnClick={handleCardOnClick}
                      />
                    </div>
                    <div className="md:hidden block">
                      <CustomCarousel
                        rows={2}
                        cols={3}
                        gapX={20}
                        gapY={20}
                        completeSliders={prepareServicesElements()}
                        numberOfCardsShownInSingleSlide={5}
                        //handleCardOnClick={handleCardOnClick}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center my-4">
                    <p className="font-semibold text-xl text-white">Orders</p>
                    <p className="text-[16px] text-white leading-4 font-medium flex items-center cursor-pointer">
                      View full History{"  "} <IoIosArrowForward />
                    </p>
                  </div>
                </>
              )}

              <div className="TABS">
                <CustomTabsDashboard
                  tabsTitleArray={["Crown and Bridge", "Dental Implant"]}
                  tabsContentObject={{
                    titleOne: arrayOfTilesOne,
                    titleTwo: arrayOfTilesTwo,
                  }}
                />
              </div>
            </div>
            <div className="CHART col-span-3 row-span-1 bg-secondary rounded-md overflow-hidden">
              <img src={graph} className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ marginLeft: 95, maxWidth: 650 }}>
        <Teeth />
      </div> */}
    </>
  );
}

// export default withAuthenticationRequired(Dashboard);
export default DashboardSP;
